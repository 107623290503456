export const DEFAULT_SYMBOL_SINGLE_LIMIT = {
    min_qty: 0,
    max_nv: 50000
}

export const SYMBOLS_WITH_MUTLIPLIER_IN_BTC = ['ETHUSD_BITMEX_SWAP', 'XRPUSD_BITMEX_SWAP', 'ETHUSDZ20_BITMEX_FUT']
export const EXCHANGES_OPTION_PRICED_IN_COIN = ['DERIBIT', 'OKEX']
export const EXCHANGES_CAN_USE_REDUCE_ONLY = ['PHEMEX', 'FTX', 'BNBFUTA']

export const EXCHANGES_CAN_SUPPORT_SMART_POSITIONS = ['OKEXSPOT', 'OKEXSPOTMARGIN', 'OKEXCONTRACT', 'OKEXUSDSWAP', 'OKEXUSDTSWAP', 'HUOBIFUT', 'HUOBIFUTSWAP', 
    'BITMEX', 'BINANCE', 'BNBFUTA', 'BNBFUTAUSDT', 'BNBFUTABUSD', 'BNBFUTACOIN', 'PHEMEX', 'PHEMEXBTCCONTRACT', 'PHEMEXUSDCONTRACT', 'FTXSPOT', 'FTXFUT', 'BYBIT', 
    'BITFINEX', 'DERIBIT', 'BTSEFUT', 'BIT', 'DYDX', 'CRYPTOFUT', 'KUCOINFUT', 'COINFLEX', 'BITGET', 'GATE']


export const MANUAL_ORDER_PROFILE = {
    name: 'atweb_order_server_pf',
    user: process.env.REACT_APP_MANUAL_ORDER_PROFILE_USER || 'lyprod_option',
    hostname: process.env.REACT_APP_MANUAL_ORDER_PROFILE_HOSTNAME || 'devland'
}