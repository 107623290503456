import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import _ from 'lodash'

import WebSocketClient, { webSocketSendData } from '../common/webSocket/WebSocketClient'

import { ELF_API_BASE_URL, ELF_WEBSOCKET_URL } from '../../configs/config'
import { secureFetch } from '../../util/util'
import { fetchFundingTags } from '../symbol/symbolAction'
import { removeSignalProfile, updateSignalProfile, updateSignedSwitchedOffProfileSymbols, updateUserProfiles } from '../profile/profileAction'
import { UPDATE_EXPOSURE_MONITOR } from '../trading/tradingAction'

let socketId = null
export const sendData = (data={}) => {
    if (socketId) {
        webSocketSendData(socketId, data)
    }
}

class NodeWebSocket extends Component {
    constructor (props) {
        super(props)
        this.state = {
            ticket: null
        }
    }

    _getWebSocketUrl () {
        const { dispatch, auth } = this.props
        return new Promise(resolve => {
            if (!auth.isLoggedIn) {
                throw new Error('User is not login')
            } else {
                dispatch(secureFetch(`${ELF_API_BASE_URL}/ws-ticket`))
                .then(response => response.json())
                .then(body => {
                    if (_.has(body, 'ticket')) {
                        resolve(`${ELF_WEBSOCKET_URL}?ticket=${body.ticket}`)
                    } else {
                        throw new Error('ticket is missing') 
                    }
                })
                .catch(error => {
                    console.log('NodeWebSocket._getWebSocketUrl error: ', error)
                })
            }
        })
    }

    _handleReceiveMessage (message={}) {
        const { dispatch } = this.props
        const messageData = JSON.parse(message.data || {})
        const { type, payload } = messageData
        switch (type) {
            case 'UPDATE_FUNDING_TAGS_SUCCESS':
                dispatch(fetchFundingTags())
                break

            case 'USER_PROFILES_UPDATED':
                dispatch(updateUserProfiles(payload))
                break

            case 'SIGNED_SWITCHED_OFF_PROFILE_SYMBOLS_UPDATED':
                dispatch(updateSignedSwitchedOffProfileSymbols(payload))
                break

            case 'SIGNAL_PROFILE_UPDATED':
                if (payload?.signalProfile) {
                    dispatch(updateSignalProfile('stored', payload.signalProfile))
                }
                break

            case 'SIGNAL_PROFILE_DELETED':
                if (payload?._id) {
                    dispatch(removeSignalProfile('stored', payload._id))
                }
                break

            case 'EXPOSURE_MONITOR_UPDATED':
                if (payload?.exposureMonitor) {
                    dispatch({
                        type: UPDATE_EXPOSURE_MONITOR,
                        exposureMonitor: payload.exposureMonitor
                    })
                }
                break

            default:
                return null
        }
    }

    render () {
        const { className, auth } = this.props
        return (
            <div className={'node-web-socket' + (className ? ` ${className}` : '')}>
                <WebSocketClient 
                    name='Node'
                    getUrl={async () => { return await this._getWebSocketUrl() }} 
                    shouldFetchUrl
                    disabled={!auth.isLoggedIn} 
                    pingPongEnabled
                    reconnectIntervalInSecond={5} 
                    onSocketOpen={({ id }) => { socketId = id }} 
                    onSocketClose={() => { socketId = null }} 
                    onReceiveMessage={(message) => { this._handleReceiveMessage(message) }} />
            </div>
        )
    }
}

NodeWebSocket.propTypes = {
    dispatch: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,

    className: PropTypes.string
}

function mapStateToProps (state) {
    return {
        auth: state.auth
    }
}

export default connect(mapStateToProps)(NodeWebSocket)