import React, { Fragment, memo, useEffect, useMemo, useState } from 'react'
import { useLatest, useLocalStorage, useMountedState, useSessionStorage, useUpdateEffect } from 'react-use'
import useEvent from 'react-use-event-hook'
import { useDispatch } from 'react-redux'
import dotProp from 'dot-prop-immutable'
import BigNumber from 'bignumber.js'
import moment from 'moment'
import _ from 'lodash'

import ReactLoading from 'react-loading'
import { FaCaretDown, FaCaretLeft, FaCaretRight, FaWandMagicSparkles } from 'react-icons/fa6'
import { GiCreditsCurrency } from 'react-icons/gi'
import { LuBadgeCheck } from 'react-icons/lu'

import Popup from '../common/popup/Popup'
import PortfolioAccountSelector from '../account/PortfolioAccountSelector'
import MarginOverviewChart from './MarginOverviewChart'

import { useShallowEqualSelector } from '../../hooks/useShallowEqualSelector'
import { areAllValuesNonEmpty, isMetSearchStringCriteria, toAbbreviateNumber } from '../../util/util'
import { AssetsStruct, BALANCE_TYPES, calculateWithdrawals, CONFIG_PER_EXCHANGE, CreditMarginInfoStruct, isCreditMarginBalance,
    MARGIN_SIDES, MarginAdjustementStruct, RISK_TYPES, WITHDRAWABLE_AMOUNT_KEYS_PER_BALANCE_TYPE, WithdrawableBalanceStruct } from '../account/accountBalanceUtil'
import { getTokenPriceInUSD } from '../../util/symbolUtil'
import { formattedTokenAmount } from '../../util/formatUtil'
import { getPortfolioNames } from '../../util/accountUtil'
import { ACCOUNT_TYPES } from '../account/TokenTransferEditor'
import { accountTransferFund, syncAccountBalances } from '../account/accountAction'
import { fetchLiquidationRatio } from './tradingAction'

export const TRANSFERABLE_COINS = ['BTC', 'ETH', 'SOL', 'USD', 'USDC', 'USDT']
const DEFAULT_CREDIT_TARGET = '1.4'
const DEFAULT_CREDIT_LIMIT = '1.45'

const TRANSFER_STATUS = {
    UNSUPPORTED: 'UNSUPPORTED',
    READY: 'READY',
    PENDING: 'PENDING',
    TRANSFERRING: 'TRANSFERRING',
    SUCCESS: 'SUCCESS',
    FAILED: 'FAILED'
}

const BULK_TRANSFER_TYPES = {
    INTRA_MAIN_ACCOUNT_TRANSFERS: 'INTRA_MAIN_ACCOUNT_TRANSFERS',
    INTER_MAIN_ACCOUNT_COLLECTIONS: 'INTER_MAIN_ACCOUNT_COLLECTIONS',
    INTER_MAIN_ACCOUNT_DISTRIBUTIONS: 'INTER_MAIN_ACCOUNT_DISTRIBUTIONS'
}

export const COIN_COLORS = {
    BTC: '#F7931A',
    ETH: '#627EEA',
    USD: 'lightslategray',
    USDC: '#2775CA',
    USDT: '#26A17B',
    SOL: '#9932CC'
}

const BALANCE_TYPE_COLORS = {
    [BALANCE_TYPES.CROSS]: '#FF6F61',
    [BALANCE_TYPES.CROSS_MARGIN]: '#F7B733',
    [BALANCE_TYPES.FUND]: '#4A90E2',
    [BALANCE_TYPES.FUNDING]: '#7B4397',
    [BALANCE_TYPES.FUTURE]: '#7B4397',
    [BALANCE_TYPES.SPOT]: '#FFCC00',
    [BALANCE_TYPES.SWAP]: '#D94F70',
    [BALANCE_TYPES.WALLET]: '#2E8B57'
}

const RISK_TYPE_COLORS = {
    [RISK_TYPES.POSITION_EFFECTIVE_RATIO.key]: '#1E90FF',
    [RISK_TYPES.ACCOUNT_MMR.key]: '#32CD32',
    [RISK_TYPES.CREDIT_MMR.key]: '#FF6347'
}

const TRANSFER_INTERVAL_PER_EXCHANGE = {
    BYBIT: 250,
    BINANCE: 200,
    BNBFUTA: 200,
    OKEX: 1200,
    PHEMEX: 2100
}

const _getCreditExchange = (_accountName='') => {
    return _accountName.includes('_') ? _.toUpper(_accountName.split('_')[0]) : null
}

const _getMarginCollectionsAndRequirements = ({ accountItems={}, selectedAccounts=[], accountBalancesPerType={},
    liquidationRatios={}, portfolioMarginAccountInfo={}, deribitPortfolioMarginAccountInfo={}, portfolioMarginVirtualAccountInfo={}, pricings={}, 
    effectiveRatioLimit='', effectiveRatioTarget='', MMRLimit='', MMRTarget='', creditLimits={}, creditTargets={},
    maxWithdrawableBalanceRate='1', transferableCoins=[] }) => {

    const marginCollectionsPerAccount = {}
    const marginRequirements = []
    const liquidationRatiosPerAccount = _.groupBy(liquidationRatios, 'acct_name')

    let _pricePerCoin = {}
    const _getTokenPrice = (_coin) => {
        return _pricePerCoin[_coin] ?? getTokenPriceInUSD(_coin, pricings)
    }

    // Credits
    const creditMarginInfoPerMainAccount = {}
    _.forEach(portfolioMarginVirtualAccountInfo, (_credit, _accountName) => {
        const { maint_margin, mmr } = _credit
        const _maintMargin = BigNumber(maint_margin || 0)
        const _mmr = BigNumber(mmr)

        const _exchange = _getCreditExchange(_accountName)
        const mainAccountName = _.get(_.find(accountItems, { is_main: '1', exchange_name: _exchange }), 'account_name')

        if (areAllValuesNonEmpty([maint_margin, mmr]) && !_.isEmpty(mainAccountName)) {
            const _creditLimit = creditLimits[_exchange] ?? DEFAULT_CREDIT_LIMIT
            const _creditTarget = creditTargets[_exchange] ?? DEFAULT_CREDIT_TARGET
            const _creditConfig = _.get(CONFIG_PER_EXCHANGE, `${_exchange}.credit`, {
                accounts: [],
                assets: {}
            })

            creditMarginInfoPerMainAccount[mainAccountName] = CreditMarginInfoStruct({
                exchange: _exchange,
                mainAccountName,
                creditDetail: _credit,
                withdrawableMargin: BigNumber.max(
                    0,
                    _maintMargin.times(_mmr.minus(_creditLimit || 0))
                ).toFixed(0, 1),
                ..._creditConfig
            })

            if (!_.isEmpty(_creditTarget) && _mmr.lt(_creditTarget)) {
                marginRequirements.push(MarginAdjustementStruct({
                    marginSide: MARGIN_SIDES.REQUIREMENT,
                    exchangeName: _exchange,
                    accountName: mainAccountName,
                    isMainAccount: true,
                    mainAccountName,
                    riskType: RISK_TYPES.CREDIT_MMR.key,
                    riskDetail: _credit,
                    riskAdjustedMarginDelta: _maintMargin.times(_mmr.minus(_creditTarget).negated()).toFixed(0, 1),
                    portfolioCurrency: 'USD',
                    acceptableAssets: AssetsStruct({
                        ..._creditConfig?.assets
                    })
                }))
            }
        }
    })

    const _getWithdrawableBalance = ({ accountName, mainAccountName, balanceType, coin }) => {
        const _balanceTypeInCamelCase = _.toLower(balanceType).replace(/_([a-z])/g, (match, p) => _.toUpper(p))
        const balanceDetail = _.get(accountBalancesPerType, `${_balanceTypeInCamelCase}.${accountName}--${_.toUpper(coin)}`)
            ?? _.get(accountBalancesPerType, `${_balanceTypeInCamelCase}.${accountName}--${_.toLower(coin)}`)

        let withdrawableAmount
        if (!_.isEmpty(balanceDetail)) {
            withdrawableAmount = _.reduce(
                WITHDRAWABLE_AMOUNT_KEYS_PER_BALANCE_TYPE[balanceType],
                (_result, _key) => (_.isNumber(_result) || !_.isEmpty(_result)) ? _result : balanceDetail[_key],
                null
            )
        }

        const decimalPrecision = (withdrawableAmount || '').includes('.') ? _.size(withdrawableAmount.split('.')[1]) : 0
        const price = _getTokenPrice(coin)
            
        return !areAllValuesNonEmpty([price, withdrawableAmount]) ? null
            : WithdrawableBalanceStruct({
                mainAccountName,
                accountName,
                balanceType,
                balanceDetail,
                coin,
                isCreditMargin: isCreditMarginBalance({
                    creditMarginInfo: creditMarginInfoPerMainAccount[mainAccountName],
                    accountName,
                    balanceType
                }),
                withdrawableAmount,
                withdrawnAmount: '0',
                decimalPrecision,
                price
            })
    }

    _.forEach(selectedAccounts, _account => {
        const { exchange_name: exchangeName, is_main: isMain, main_acct_name } = accountItems[_account] || {}
        const isMainAccount = isMain === '1'
        const mainAccountName = isMainAccount ? _account : main_acct_name
        const _config = _.get(CONFIG_PER_EXCHANGE, exchangeName)

        if (_.has(accountItems, mainAccountName) && !_.isEmpty(_config))  {

            const { alias: _exchangeAlias, marginPool, crossMarginGroupAssets, portfolioMarginAssets } = _config
            const _accountAlias = !_.isEmpty(_exchangeAlias) ? _.replace(_account, _.toLower(`${exchangeName}_`), _.toLower(`${_exchangeAlias}_`)) : null
        
            // Cross Margin Groups
            const _liquidationRatios = liquidationRatiosPerAccount[_account] ?? liquidationRatiosPerAccount[_accountAlias]
            if (!_.isEmpty(_liquidationRatios)) {
                _.forEach(
                    _.groupBy(_liquidationRatios, _ratio => _ratio.cross_margin_group || _ratio.product_name),
                    _ratios => {
                        const _ratio = _.head(_ratios)
                        const { acct_name: _accountName, cross_margin_group: _crossMarginGroup, adjusted_position_usd, effective_ratio, margin_mark_token: portfolioCurrency } = _ratio
                        const _adjustedPositionUSD = BigNumber(adjusted_position_usd || 0)

                        if (areAllValuesNonEmpty([_crossMarginGroup, adjusted_position_usd, effective_ratio, portfolioCurrency]) && _adjustedPositionUSD.gt(0)) {
                            const { balanceTypes, coins } = crossMarginGroupAssets[_crossMarginGroup] || {}
                            const _effectiveRatio = BigNumber(effective_ratio).abs()
        
                            if (!_.isEmpty(effectiveRatioLimit)) {
                                const riskAdjustedMarginDelta = BigNumber.max(
                                    0,
                                    _adjustedPositionUSD.times(_effectiveRatio.minus(effectiveRatioLimit)).toFixed(0, 1)
                                ).toFixed(0, 1)

                                const withdrawableBalances = []
                                const _coins = _.intersection(coins, transferableCoins)
                                _.forEach(balanceTypes, (_balanceType) => {
                                    _.forEach(_coins, _coin => {
                                        const _withdrawableBalance = _getWithdrawableBalance({
                                            accountName: _accountName,
                                            mainAccountName,
                                            balanceType: _balanceType,
                                            coin: _coin 
                                        })
                                        if (!_.isEmpty(_withdrawableBalance)) {
                                            withdrawableBalances.push(_withdrawableBalance)
                                        }
                                    })
                                })

                                marginCollectionsPerAccount[_account] = _.concat(marginCollectionsPerAccount[_account] || [], [
                                    MarginAdjustementStruct({
                                        marginSide: MARGIN_SIDES.COLLECTION,
                                        exchangeName,
                                        accountName: _accountName,
                                        isMainAccount,
                                        mainAccountName,
                                        riskType: RISK_TYPES.POSITION_EFFECTIVE_RATIO.key,
                                        riskDetail: _ratio,
                                        riskAdjustedMarginDelta,
                                        portfolioCurrency,
                                        withdrawableBalances
                                    })
                                ])
                            }

                            if (!_.isEmpty(effectiveRatioTarget) && _effectiveRatio.lt(effectiveRatioTarget)) {
                                marginRequirements.push(MarginAdjustementStruct({
                                    marginSide: MARGIN_SIDES.REQUIREMENT,
                                    exchangeName,
                                    accountName: _accountName,
                                    isMainAccount,
                                    mainAccountName,
                                    riskType: RISK_TYPES.POSITION_EFFECTIVE_RATIO.key,
                                    riskDetail: _ratio,
                                    riskAdjustedMarginDelta: _adjustedPositionUSD.times(
                                            _effectiveRatio.minus(effectiveRatioTarget).negated()
                                        ).toFixed(0, 1),
                                    portfolioCurrency,
                                    acceptableAssets: AssetsStruct({
                                        balanceTypes,
                                        coins
                                    })
                                }))
                            }
                        } else {
                            // TO IMPLEMENT FOR ISOLATED MARGIN POSITION_EFFECTIVE_RATIO
                        }
                    }
                )
            }

            // Portfolio Margin Accounts
            const currencyPMAPairs = []
            const _portfolioMarginAccount = _.get(portfolioMarginAccountInfo, _account) ?? _.get(portfolioMarginAccountInfo, _accountAlias)
            if (!_.isEmpty(_portfolioMarginAccount)) {
                currencyPMAPairs.push(['USD', _portfolioMarginAccount])
            }

            const _deribitPortfolioMarginAccounts = _.filter(deribitPortfolioMarginAccountInfo, { acct_name: _account })
            _.forEach(_deribitPortfolioMarginAccounts, _pma => {
                currencyPMAPairs.push([_pma.coin, _pma])
            })

            _.forEach(currencyPMAPairs, _pair => {
                const [portfolioCurrency, _pma] = _pair
                const { balanceTypes, coins } = portfolioMarginAssets[portfolioCurrency] || {}
                const _portfolioCurrencyPrice = ['USD', 'USDC'].includes(portfolioCurrency) ? 1 : _getTokenPrice(portfolioCurrency)

                if (!_.isEmpty(balanceTypes) && !_.isEmpty(coins)) {
                    const { acct_name: _accountName, maint_margin, mmr } = _pma

                    if (areAllValuesNonEmpty([maint_margin, mmr])) {
                        const _maintMargin = BigNumber(maint_margin)
                        const _mmr = BigNumber(mmr)

                        if (!_.isEmpty(MMRLimit)) {
                            const riskAdjustedMarginDelta = BigNumber.max(
                                0,
                                _maintMargin.times(_mmr.minus(MMRLimit))
                            ).times(_portfolioCurrencyPrice).toFixed(0, 1)
                            
                            const withdrawableBalances = []
                            const _coins = _.intersection(transferableCoins, coins)
                            _.forEach(balanceTypes, (_balanceType) => {
                                _.forEach(_coins, _coin => {
                                    const _withdrawableBalance = _getWithdrawableBalance({
                                        accountName: _account,
                                        mainAccountName,
                                        balanceType: _balanceType,
                                        coin: _coin 
                                    }) ?? _getWithdrawableBalance({
                                        accountName: _accountAlias,
                                        mainAccountName,
                                        balanceType: _balanceType,
                                        coin: _coin
                                    })
                                    if (!_.isEmpty(_withdrawableBalance)) {
                                        withdrawableBalances.push(_withdrawableBalance)
                                    }
                                })
                            })

                            marginCollectionsPerAccount[_account] = _.concat(marginCollectionsPerAccount[_account] || [], [
                                MarginAdjustementStruct({
                                    marginSide: MARGIN_SIDES.COLLECTION,
                                    exchangeName,
                                    accountName: _accountName,
                                    isMainAccount,
                                    mainAccountName,
                                    riskType: RISK_TYPES.ACCOUNT_MMR.key,
                                    riskDetail: _pma,
                                    riskAdjustedMarginDelta,
                                    portfolioCurrency,
                                    withdrawableBalances
                                })
                            ])
                        }

                        if (!_.isEmpty(MMRTarget) && _mmr.lt(MMRTarget)) {
                            const riskAdjustedMarginDelta = _maintMargin.times(_mmr.minus(MMRTarget).negated())
                                .times(_portfolioCurrencyPrice)
                                .toFixed(0, 1)
                            marginRequirements.push(MarginAdjustementStruct({
                                marginSide: MARGIN_SIDES.REQUIREMENT,
                                exchangeName,
                                accountName: _accountName,
                                isMainAccount,
                                mainAccountName,
                                riskType: RISK_TYPES.ACCOUNT_MMR.key,
                                riskDetail: _pma,
                                riskAdjustedMarginDelta,
                                portfolioCurrency,
                                acceptableAssets: AssetsStruct({
                                    balanceTypes,
                                    coins
                                })
                            }))
                        }
                    }
                }
            })
        
            // Risk Free Margin
            const { balanceType, isRiskFree } = marginPool || {}
            if (!_.isEmpty(marginPool) && isRiskFree) {
                const withdrawableBalances = []
                _.forEach(transferableCoins, _coin => {
                    const _withdrawableBalance = _getWithdrawableBalance({
                        accountName: _account,
                        mainAccountName,
                        balanceType,
                        coin: _coin 
                    }) ?? _getWithdrawableBalance({
                        accountName: _accountAlias,
                        mainAccountName,
                        balanceType,
                        coin: _coin 
                    })
                    if (!_.isEmpty(_withdrawableBalance)) {
                        withdrawableBalances.push(_withdrawableBalance)
                    }
                })

                marginCollectionsPerAccount[_account] = _.concat(marginCollectionsPerAccount[_account] || [], [
                    MarginAdjustementStruct({
                        marginSide: MARGIN_SIDES.COLLECTION,
                        exchangeName,
                        accountName: _account,
                        isMainAccount,
                        mainAccountName,
                        riskType: RISK_TYPES.RISK_FREE_BALANCE.key,
                        riskDetail: null,
                        riskAdjustedMarginDelta: Infinity,
                        withdrawableBalances
                    })
                ])
            }
        }
    })

    // If an account has both account mmr and position effective ratio risk types,
    // to avoid double counting and ensure that both risks are covered when collecting funds,
    // we will only keep the type with the lowest valueUSD on marginCollectionsPerAccount
    _.forEach(marginCollectionsPerAccount, (_marginCollections, _account) => {
        const [riskFreeCollections, riskyCollections] = _.partition(_marginCollections, _collection => _collection.riskType === RISK_TYPES.RISK_FREE_BALANCE.key)
        const riskyCollectionsPerPortoflioCurrency = _.groupBy(
            _.filter(riskyCollections, _margin => !_.isEmpty(_margin.portfolioCurrency)),
            'portfolioCurrency'
        )


        const filteredCollections = [...riskFreeCollections]
        _.forEach(riskyCollectionsPerPortoflioCurrency, (_collections) => {
            const _pickedCollection = _.minBy(_collections, _margin => {
                const { riskAdjustedMarginDelta, withdrawableBalances, riskType } = _margin
                const { totalWithdrawableMargin } = calculateWithdrawals({
                    withdrawableBalances,
                    riskAdjustedMarginDelta,
                    riskType,
                    maxWithdrawableBalanceRate
                })
                return Number(totalWithdrawableMargin)
            })
            filteredCollections.push(_pickedCollection)
        })
        marginCollectionsPerAccount[_account] = filteredCollections
    })
    
    return {
        creditMarginInfoPerMainAccount,
        marginCollections: _.flatten(_.values(marginCollectionsPerAccount)),
        marginRequirements
    }
}

const _getMarginCollectionOverviewPerMainAccount = ({ marginCollections=[], creditMarginInfoPerMainAccount={}, maxWithdrawableBalanceRate='1' }) => {
    const marginCollectionsPerMainAccount = _.groupBy(marginCollections, 'mainAccountName')
    const collectionOverviewPerMainAccount = {}

    _.forEach(marginCollectionsPerMainAccount, (_collections, _mainAccount) => {
        collectionOverviewPerMainAccount[_mainAccount] = {
            mainAccountName: _mainAccount,
            creditMarginInfo: creditMarginInfoPerMainAccount[_mainAccount],
            totalCollectibleMargin: '0',
            marginInfoPerCoin: {}
        }

        const _mainAccountCollections = collectionOverviewPerMainAccount[_mainAccount]
        const _initialCreditMargin = _mainAccountCollections.creditMarginInfo?.withdrawableMargin

        let _remainingCreditWithdrawableMargin = _initialCreditMargin
        const _remainingCreditWithdrawableMarginPerCoin = {}

        for (const _margin of _collections) {
            const { riskType, riskAdjustedMarginDelta, withdrawableBalances } = _margin
            const { totalWithdrawableMargin, remainingCreditWithdrawableMargin } = calculateWithdrawals({
                withdrawableBalances,
                riskAdjustedMarginDelta,
                creditWithdrawableMargin: _remainingCreditWithdrawableMargin,
                riskType,
                maxWithdrawableBalanceRate
            })

            _mainAccountCollections.totalCollectibleMargin = BigNumber(_mainAccountCollections.totalCollectibleMargin).plus(totalWithdrawableMargin).toString()
            _remainingCreditWithdrawableMargin = remainingCreditWithdrawableMargin

            // calculate for the each coin collection
            const _withdrawablesPerCoin = _.groupBy(withdrawableBalances, 'coin')
            _.forEach(_withdrawablesPerCoin, (_withdrawables, _coin) => {
                if (!_.has(_mainAccountCollections.marginInfoPerCoin, _coin)) {
                    _mainAccountCollections.marginInfoPerCoin[_coin] = {
                        coin: _coin,
                        withdrawableBalances: [],
                        totalCollectibleAmount: '0',
                        totalCollectibleMargin: '0'
                    }
                    _remainingCreditWithdrawableMarginPerCoin[_coin] = _initialCreditMargin
                }

                const _coinMarginInfo = _mainAccountCollections.marginInfoPerCoin[_coin]
                _coinMarginInfo.withdrawableBalances.push(..._withdrawables)
                
                const { withdrawalPerCoin, remainingCreditWithdrawableMargin: _reaminingCreditMargin } = calculateWithdrawals({
                    withdrawableBalances: _withdrawables,
                    riskAdjustedMarginDelta,
                    creditWithdrawableMargin: _remainingCreditWithdrawableMarginPerCoin[_coin],
                    riskType,
                    maxWithdrawableBalanceRate
                })

                const { withdrawableAmount: _withdrawAmount, withdrawableMargin: _withdrawMargin } = withdrawalPerCoin[_coin]
                _coinMarginInfo.totalCollectibleAmount = BigNumber(_coinMarginInfo.totalCollectibleAmount).plus(_withdrawAmount).toString()
                _coinMarginInfo.totalCollectibleMargin = BigNumber(_coinMarginInfo.totalCollectibleMargin).plus(_withdrawMargin).toString()
                _remainingCreditWithdrawableMarginPerCoin[_coin] = _reaminingCreditMargin
            })
        }
    })

    return collectionOverviewPerMainAccount
}

const _getMarginRequirementOverviewPerMainAccount = (marginRequirements=[]) => {

    const requirementOverviewPerMainAccount = {}

    _.forEach(marginRequirements, _margin => {
        const { mainAccountName, riskAdjustedMarginDelta, acceptableAssets } = _margin
        const _acceptableCoins = (acceptableAssets?.coins || []).join(',')

        if (!_.has(requirementOverviewPerMainAccount, mainAccountName)) {
            requirementOverviewPerMainAccount[mainAccountName] = {
                mainAccountName,
                requirementPerCoins: {},
                totalRequiredMargin: '0'
            }
        }

        const _mainAccountMarginRequirement = requirementOverviewPerMainAccount[mainAccountName]
        const _requirementPerCoins = _mainAccountMarginRequirement.requirementPerCoins
        if (!_.has(_requirementPerCoins, _acceptableCoins)) {
            _requirementPerCoins[_acceptableCoins] = {
                acceptableCoins: _acceptableCoins,
                margins: [],
                totalRequiredMargin: '0'
            }
        }

        const _requirement = _requirementPerCoins[_acceptableCoins]
        _requirement.margins.push(_margin)
        _requirement.totalRequiredMargin = BigNumber(_requirement.totalRequiredMargin).plus(riskAdjustedMarginDelta).toString()
        _mainAccountMarginRequirement.totalRequiredMargin = BigNumber(_mainAccountMarginRequirement.totalRequiredMargin).plus(riskAdjustedMarginDelta).toString()
    })

    return requirementOverviewPerMainAccount
}

const TransferStruct = ({ originAccount, originBalanceType, originAccountType,
    destinationAccount, destinationBalanceType, destinationAccountType,
    coin, amount, message='' }) => {
    return {
        originAccount, originBalanceType, originAccountType,
        destinationAccount, destinationBalanceType, destinationAccountType,
        coin, amount, message,
        status: areAllValuesNonEmpty([originAccount, originAccountType, destinationAccount, destinationAccountType]) ? TRANSFER_STATUS.READY : TRANSFER_STATUS.UNSUPPORTED
    }
}

const CollectibleMarginByCoinsStruct = ({ mainAccountName, marginPoolBalanceType, marginPoolAccountType, totalCollectibleMargin='0' }) => {
    return { mainAccountName, marginPoolBalanceType, marginPoolAccountType, totalCollectibleMargin}
}

const MarginAllocationStruct = ({ key=0, title='' }) => {
    return {
        key,
        title,
        totalRequiredMargin: '0',
        totalCollectibleMargin: '0',
        intraMainAccountTransfers: [],
        interMainAccountTransfer: {
            collections: [],
            crossMainAccountTransfers:[],
            distributions: []
        }
    }
}

const _generateMarginAllocation = ({ marginRequirements=[], marginCollections=[], creditMarginInfoPerMainAccount={}, maxWithdrawableBalanceRate='1' }) => {
    const _marginRequirements = _.sortBy(
        _.cloneDeep(marginRequirements),
        _requirement => _.size(_requirement.acceptableAssets?.coins || [])
    )
    const _marginCollections = _.cloneDeep(marginCollections)
    const _creditWithdrawableMarginPerMainAccount = _.mapValues(creditMarginInfoPerMainAccount, 'withdrawableMargin')

    const result = MarginAllocationStruct({})

    const _findIntraMainAccountCollection = (_mainAccount, _acceptableCoins=[]) => {
        let selectedMarginCollection, selectedWithdrawableBalance
        let cappedWithdrawableAmount, cappedWithdrawableMargin
    
        _.forEach(_marginCollections, _collection => {
            const { riskType, mainAccountName, riskAdjustedMarginDelta, withdrawableBalances } = _collection

            if (mainAccountName === _mainAccount && !_.isNil(riskAdjustedMarginDelta) && !_.isEmpty(withdrawableBalances)) {
                _.forEach(withdrawableBalances, _withdrawable => {
                    const { coin: _coin } = _withdrawable
                    if (_acceptableCoins.includes(_coin)) {
                        const { withdrawalPerCoin } = calculateWithdrawals({
                            withdrawableBalances: [_withdrawable],
                            riskAdjustedMarginDelta,
                            creditWithdrawableMargin: _creditWithdrawableMarginPerMainAccount[mainAccountName],
                            riskType,
                            maxWithdrawableBalanceRate
                        })

                        const { withdrawableAmount: _cappedAmount, withdrawableMargin: _cappedWithdrawableMargin } = withdrawalPerCoin[_coin] || {}
                        const _cappedMargin = BigNumber(_cappedWithdrawableMargin || 0)
                        if (_cappedMargin.gt(0) && (_.isNil(selectedMarginCollection) || _cappedMargin.gt(cappedWithdrawableMargin || 0))) {
                            selectedMarginCollection = _collection
                            selectedWithdrawableBalance = _withdrawable
                            cappedWithdrawableAmount = _cappedAmount
                            cappedWithdrawableMargin = _cappedMargin.toString()
                        }
                    }
                })
            }
        })

        return {
            selectedMarginCollection,
            selectedWithdrawableBalance,
            cappedWithdrawableAmount,
            cappedWithdrawableMargin
        }
    }

    const _getCollectibleMarginByCoinsPerMainAccount = (_acceptableCoins=[]) => {
        const result = {}

        for (const _collection of _marginCollections) {
            const { exchangeName, mainAccountName, riskType, riskAdjustedMarginDelta, withdrawableBalances } = _collection
            
            if (!_.has(result, mainAccountName)) {
                const _balanceType = _.get(CONFIG_PER_EXCHANGE, `${exchangeName}.marginPool.balanceType`, 'ANY')
                result[mainAccountName] = CollectibleMarginByCoinsStruct({
                    mainAccountName,
                    marginPoolBalanceType: _balanceType,
                    marginPoolAccountType: _.get(CONFIG_PER_EXCHANGE, `${exchangeName}.accountTypePerBalance.${_balanceType}`),
                    totalCollectibleMargin: '0'
                })
            }

            const { totalWithdrawableMargin } = calculateWithdrawals({
                withdrawableBalances,
                riskAdjustedMarginDelta,
                creditWithdrawableMargin: _creditWithdrawableMarginPerMainAccount[mainAccountName],
                coins: _acceptableCoins,
                riskType,
                maxWithdrawableBalanceRate
            })

            if (BigNumber(totalWithdrawableMargin || 0).gt(0)) {
                result[mainAccountName].totalCollectibleMargin = BigNumber(result[mainAccountName].totalCollectibleMargin).plus(totalWithdrawableMargin).toString()
            }
        }

        return result
    }

    const _isValidCollectibleMarginByCoins = (_collectible={}) => {
        return !_.isEmpty(_collectible)
            && areAllValuesNonEmpty([_collectible.mainAccountName, _collectible.marginPoolBalanceType, _collectible.totalCollectibleMargin])
            && BigNumber(_collectible.totalCollectibleMargin).gt(0)
    }

    const _coordinateCrossAccountMarginTransfer = ({
        _collecitonTransfers=[],
        _destinationMainAccount='',
        _destinationMarginPoolBalanceType='',
        _destinationMarginPoolAccountType='',
        _destinationAccount='',
        _destinationBalanceType='',
        _destinationAccountType=''
    }) => {
        _.forEach(_collecitonTransfers, _transfer => {
            const { coin: _coin, amount: _amount } = _transfer

            const _collectionTransferFeature = {
                originAccount: _transfer.originAccount,
                originBalanceType: _transfer.originBalanceType,
                originAccountType: _transfer.originAccountType,
                destinationAccount: _transfer.destinationAccount,
                destinationBalanceType: _transfer.destinationBalanceType,
                destinationAccountType: _transfer.destinationAccountType,
                coin: _coin
            }
            
            const _crossMainAccountTransferFeature = {
                originAccount: _transfer.destinationAccount,
                originBalanceType: _transfer.destinationBalanceType,
                destinationAccount: _destinationMainAccount,
                destinationBalanceType: _destinationMarginPoolBalanceType,
                coin: _coin
            }

            const _distributionTransferFeature = {
                originAccount: _destinationMainAccount,
                originBalanceType: _destinationMarginPoolBalanceType,
                originAccountType: _destinationMarginPoolAccountType,
                destinationAccount: _destinationAccount,
                destinationBalanceType: _destinationBalanceType,
                destinationAccountType: _destinationAccountType,
                coin: _coin
            }


            if (_collectionTransferFeature.originAccount !== _collectionTransferFeature.destinationAccount
            || _collectionTransferFeature.originBalanceType !== _collectionTransferFeature.destinationBalanceType) {
                const _collectionTransfer = _.find(result.interMainAccountTransfer.collections, { ..._collectionTransferFeature })
                if (_collectionTransfer) {
                    _collectionTransfer.amount = BigNumber(_collectionTransfer.amount).plus(_amount).toString()
                } else {
                    result.interMainAccountTransfer.collections.push(TransferStruct({
                        ..._collectionTransferFeature,
                        amount: _amount
                    }))
                }
            }

            const _crossMainAccountTransfer = _.find(result.interMainAccountTransfer.crossMainAccountTransfers, { ..._crossMainAccountTransferFeature })
            if (_crossMainAccountTransfer) {
                _crossMainAccountTransfer.amount = BigNumber(_crossMainAccountTransfer.amount).plus(_amount).toString()
            } else {
                result.interMainAccountTransfer.crossMainAccountTransfers.push(TransferStruct({
                    ..._crossMainAccountTransferFeature,
                    amount: _amount
                }))
            }

            if (_distributionTransferFeature.originAccount !== _distributionTransferFeature.destinationAccount
            || _distributionTransferFeature.originBalanceType !== _distributionTransferFeature.destinationBalanceType) {
                const _distributionTransfer = _.find(result.interMainAccountTransfer.distributions, { ..._distributionTransferFeature })
                if (_distributionTransfer) {
                    _distributionTransfer.amount = BigNumber(_distributionTransfer.amount).plus(_amount).toString()
                } else {
                    result.interMainAccountTransfer.distributions.push(TransferStruct({
                        ..._distributionTransferFeature,
                        amount: _amount
                    }))
                }
            }
        })
    }
    
    const _pickedCollectibleMarginPerCoins = {}

    _.forEach(_marginRequirements, _requirement => {
        const { exchangeName: destinationExchangeName, accountName: destinationAccount, mainAccountName, riskAdjustedMarginDelta, acceptableAssets } = _requirement
        const acceptableCoins = acceptableAssets?.coins || []
        const destinationBalanceType = _.get(acceptableAssets, 'balanceTypes.0')

        let _remainingRequiredMargin = BigNumber(riskAdjustedMarginDelta || 0)
        result.totalRequiredMargin = BigNumber(result.totalRequiredMargin || 0).plus(_remainingRequiredMargin).toString()

        if (!_.isEmpty(acceptableCoins) && !_.isEmpty(destinationBalanceType) && _remainingRequiredMargin.gt(0)) {

            // Intra Main Account Transfers
            while (_remainingRequiredMargin.gt(0)) {
                const { selectedMarginCollection, selectedWithdrawableBalance, cappedWithdrawableAmount, cappedWithdrawableMargin } = _findIntraMainAccountCollection(mainAccountName, acceptableCoins)

                if (_.isNil(selectedMarginCollection) || BigNumber(cappedWithdrawableAmount || 0).lte(0)) {
                    break
                } else {
                    const { exchangeName: originExchangeName } = selectedMarginCollection
                    const { coin, accountName: originAccount, balanceType: originBalanceType, decimalPrecision, price, isCreditMargin } = selectedWithdrawableBalance
                    const _fulfilledMargin = BigNumber.min(_remainingRequiredMargin, cappedWithdrawableMargin)
                    const amount = BigNumber.min(
                        cappedWithdrawableAmount,
                        _fulfilledMargin.div(price).toFixed(decimalPrecision, 0)
                    ).toString()

                    result.intraMainAccountTransfers.push(TransferStruct({
                        originAccount,
                        originBalanceType,
                        originAccountType: _.get(CONFIG_PER_EXCHANGE, `${originExchangeName}.accountTypePerBalance.${originBalanceType}`),
                        destinationAccount,
                        destinationBalanceType,
                        destinationAccountType: _.get(CONFIG_PER_EXCHANGE, `${destinationExchangeName}.accountTypePerBalance.${destinationBalanceType}`),
                        coin,
                        amount
                    }))

                    _remainingRequiredMargin = BigNumber(_remainingRequiredMargin).minus(_fulfilledMargin)
                    result.totalCollectibleMargin = BigNumber(result.totalCollectibleMargin).plus(_fulfilledMargin).toString()
                    selectedMarginCollection.riskAdjustedMarginDelta = BigNumber(selectedMarginCollection.riskAdjustedMarginDelta).minus(_fulfilledMargin).toString()
                    selectedWithdrawableBalance.withdrawnAmount = BigNumber(selectedWithdrawableBalance.withdrawnAmount).plus(amount).toString()
                    
                    if (isCreditMargin) {
                        _creditWithdrawableMarginPerMainAccount[mainAccountName] = BigNumber(_creditWithdrawableMarginPerMainAccount[mainAccountName] || 0).minus(_fulfilledMargin).toString()
                    }
                }
            }

            // Inter Main Account Transfers
            if (_remainingRequiredMargin.gt(0)) {
                const destinationMarginPoolBalanceType = _.get(CONFIG_PER_EXCHANGE, `${destinationExchangeName}.marginPool.balanceType`, 'ANY')
                const collectionTransfers = []
                let _pickedCollectibleMarginByCoins // = _pickedCollectibleMarginPerCoins[acceptableCoins.toSorted().join(',')]

                while (_remainingRequiredMargin.gt(0)) {
                    if (!_isValidCollectibleMarginByCoins(_pickedCollectibleMarginByCoins)) {
                        const collectibleMarginByCoinsPerMainAccount = _getCollectibleMarginByCoinsPerMainAccount(acceptableCoins)
                        _pickedCollectibleMarginByCoins = _.maxBy(_.values(collectibleMarginByCoinsPerMainAccount), _collectible => Number(_collectible.totalCollectibleMargin))
                        if (!_isValidCollectibleMarginByCoins(_pickedCollectibleMarginByCoins)) {
                            break
                        } else {
                            _pickedCollectibleMarginPerCoins[acceptableCoins.toSorted().join(',')] = _pickedCollectibleMarginByCoins
                        }
                    }

                    // Build Collections
                    const { mainAccountName: collectionAccount, marginPoolBalanceType, marginPoolAccountType } = _pickedCollectibleMarginByCoins
                    const { selectedMarginCollection, selectedWithdrawableBalance, cappedWithdrawableAmount, cappedWithdrawableMargin } = _findIntraMainAccountCollection(collectionAccount, acceptableCoins)
                    if (_.isNil(selectedMarginCollection) || BigNumber(cappedWithdrawableAmount || 0).lte(0)) {
                        break
                    } else {
                        const { exchangeName: originExchangeName } = selectedMarginCollection
                        const { coin, accountName: originAccount, balanceType: originBalanceType, decimalPrecision, price, isCreditMargin } = selectedWithdrawableBalance
                        const _fulfilledMargin = BigNumber.min(_remainingRequiredMargin, cappedWithdrawableMargin)
                        const amount = BigNumber.min(
                            cappedWithdrawableAmount,
                            _fulfilledMargin.div(price).toFixed(decimalPrecision)
                        ).toString()
    
                        collectionTransfers.push(TransferStruct({
                            originAccount,
                            originBalanceType,
                            originAccountType: _.get(CONFIG_PER_EXCHANGE, `${originExchangeName}.accountTypePerBalance.${originBalanceType}`),
                            destinationAccount: collectionAccount,
                            destinationBalanceType: marginPoolBalanceType,
                            destinationAccountType: marginPoolAccountType,
                            coin,
                            amount
                        }))

                        _remainingRequiredMargin = BigNumber(_remainingRequiredMargin).minus(_fulfilledMargin)
                        result.totalCollectibleMargin = BigNumber(result.totalCollectibleMargin).plus(_fulfilledMargin).toString()
                        selectedMarginCollection.riskAdjustedMarginDelta = BigNumber(selectedMarginCollection.riskAdjustedMarginDelta).minus(_fulfilledMargin).toString()
                        selectedWithdrawableBalance.withdrawnAmount = BigNumber(selectedWithdrawableBalance.withdrawnAmount).plus(amount).toString()       
                        _pickedCollectibleMarginByCoins.totalCollectibleMargin = BigNumber(_pickedCollectibleMarginByCoins.totalCollectibleMargin).minus(_fulfilledMargin).toString()
                        
                        if (isCreditMargin) {
                            _creditWithdrawableMarginPerMainAccount[collectionAccount] = BigNumber(_creditWithdrawableMarginPerMainAccount[collectionAccount]).minus(_fulfilledMargin).toString()
                        }
                    }
                }

                if (!_.isEmpty(collectionTransfers)) {
                    _coordinateCrossAccountMarginTransfer({
                        _collecitonTransfers: collectionTransfers,
                        _destinationMainAccount: mainAccountName,
                        _destinationMarginPoolBalanceType: destinationMarginPoolBalanceType,
                        _destinationMarginPoolAccountType: _.get(CONFIG_PER_EXCHANGE, `${destinationExchangeName}.accountTypePerBalance.${destinationMarginPoolBalanceType}`),
                        _destinationAccount: destinationAccount,
                        _destinationBalanceType: destinationBalanceType,
                        _destinationAccountType: _.get(CONFIG_PER_EXCHANGE, `${destinationExchangeName}.accountTypePerBalance.${destinationBalanceType}`)
                    })
                }
            }
        }
    })

    return result
}

function PortfolioGuard () {

    const isMounted = useMountedState()
    const dispatch = useDispatch()
    const liquidationRatios = useShallowEqualSelector(state => _.get(state, `trading.liquidationRatios`)) || {}
    const { items: accountItems, portfolioMarginAccountInfo, portfolioMarginVirtualAccountInfo, deribitPortfolioMarginAccountInfo } = useShallowEqualSelector(state => _.get(state, 'account')) || {}
    const accountBalancesPerType = useShallowEqualSelector(state => _.get(state, `account.balance`)) || {}
    const pricings = useShallowEqualSelector(state => _.get(state, 'symbol.pricings')) || {}

    const [maxWithdrawableBalanceRate, setMaxWithdrawableBalanceRate] = useSessionStorage('portfolio-guard--max-withdrawable-balance-rate', '0.6')
    const [effectiveRatioLimit, setEffectiveRatioLimit] = useLocalStorage('portfolio-guard--effective-ratio-limit', '0.22')
    const [effectiveRatioTarget, setEffectiveRatioTarget] = useLocalStorage('portfolio-guard--effective-ratio-target', '0.2')
    const [MMRLimit, setMMRLimit] = useLocalStorage('portfolio-guard--mmr-limit', '2.5')
    const [MMRTarget, setMMRTarget] = useLocalStorage('portfolio-guard--mmr-target', '2')
    const [creditLimits, setCreditLimits] = useLocalStorage('portfolio-guard--credit-limits', {})
    const [creditTargets, setCreditTargets] = useLocalStorage('portfolio-guard--credit-targets', {})

    const [portfolios, setPortfolios] = useLocalStorage('portfolio-guard--portfolios', ['prop'])
    const [marginCollectonAccounts, setMarginCollectionAccounts] = useSessionStorage('portfolio-guard--margin-collection-accounts', [])
    const [riskType, setRiskType] = useSessionStorage('portfolio-guard--risk-type', RISK_TYPES.POSITION_EFFECTIVE_RATIO.key)
    const [transferableCoins, setTransferableCoins] = useSessionStorage('portfolio-guard--transferable-coins', TRANSFERABLE_COINS)
    const [shouldShowRequirementParams, setShouldShowRequirementParams] = useSessionStorage('portfolio-guard--should-show-requirement-params', true)
    const [shouldShowCollectionParams, setShouldShowCollectionParams] = useSessionStorage('portfolio-guard--should-show-collection-params', true)
    const [shouldShowRequirementOverview, setShouldShowRequirementOverview] = useSessionStorage('portfolio-guard--should-show-requirement-overview', true)
    const [shouldShowCollectionOverview, setShouldShowCollectionOverview] = useSessionStorage('portfolio-guard--should-show-collection-overview', true)
    const [shouldShowRequirementDetail, setShouldShowRequirementDetail] = useSessionStorage('portfolio-guard--should-show-requirement-detail', true)
    const [shouldShowCollectionDetail, setShouldShowCollectionDetail] = useSessionStorage('portfolio-guard--should-show-collection-detail', true)
    const [requirementSearchString, setRequirementSearchString] = useState('')
    const [collectionSearchString, setCollectionSearchString] = useState('')
    const [shouldShowChart, setShouldShowChart] = useSessionStorage('portfolio-guard--should-show-chart', true)
    const [marginAllocation, setMarginAllocation] = useState(MarginAllocationStruct({}))

    const [isFetchingLiquidationRatio, setIsFetchingLiquidationRatio] = useState(false)
    const [isTransferringIntraMainAccountTransfers, setIsTransferringIntraMainAccountTransfers] = useState(false)
    const [isTransferringInterMainAccountCollections, setIsTransferringInterMainAccountCollections] = useState(false)
    const [isTransferringInterMainAccountDistributions, setIsTransferringInterMainAccountDistributions] = useState(false)
    const latestMarginAllocationKey = useLatest(marginAllocation.key)
    const latestIsTransferringIntraMainAccountTransfers = useLatest(isTransferringIntraMainAccountTransfers)
    const latestIsTransferringInterMainAccountCollections = useLatest(isTransferringInterMainAccountCollections)
    const latestIsTransferringInterMainAccountDistributions = useLatest(isTransferringInterMainAccountDistributions)

    const selectedPortfoliosAccounts = useMemo(() => {
        return _.reduce(accountItems, (_accounts, _accountItem) => {
            if (portfolios.includes(_accountItem.portfolio_name)) {
                _accounts.push(_accountItem.account_name)
            }
            return _accounts
        }, [])
    }, [_.size(accountItems), _.size(portfolios)])

    useEffect(() => {
        setMarginCollectionAccounts(selectedPortfoliosAccounts)
    }, [selectedPortfoliosAccounts])

    useUpdateEffect(() => {
        if (isTransferringIntraMainAccountTransfers) {
            setIsTransferringIntraMainAccountTransfers(false)
        }
        if (isTransferringInterMainAccountCollections) {
            setIsTransferringInterMainAccountCollections(false)
        }
        if (isTransferringInterMainAccountDistributions) {
            setIsTransferringInterMainAccountDistributions(false)
        }
    }, [marginAllocation.key])

    const allPortfolioNames = getPortfolioNames()
    const { creditMarginInfoPerMainAccount, marginCollections, marginRequirements } = _getMarginCollectionsAndRequirements({
        accountItems,
        selectedAccounts: selectedPortfoliosAccounts,
        accountBalancesPerType,
        liquidationRatios,
        portfolioMarginAccountInfo,
        portfolioMarginVirtualAccountInfo,
        deribitPortfolioMarginAccountInfo,
        pricings,
        transferableCoins,
        effectiveRatioLimit,
        effectiveRatioTarget,
        MMRLimit,
        MMRTarget,
        creditLimits,
        creditTargets,
        maxWithdrawableBalanceRate: maxWithdrawableBalanceRate || '0'
    }) 

    const marginRequirementsPerRiskType = _.groupBy(marginRequirements, 'riskType')
    const selectedRiskTypeMarginRequirements = marginRequirementsPerRiskType[riskType] || []
    const selectedAccountsMarginCollections = _.filter(marginCollections, _margin => marginCollectonAccounts.includes(_margin.accountName))

    const collectionOverviewPerMainAccount = _getMarginCollectionOverviewPerMainAccount({
        marginCollections: selectedAccountsMarginCollections,
        creditMarginInfoPerMainAccount,
        maxWithdrawableBalanceRate
    })
    const requirementOverviewPerMainAccount = _getMarginRequirementOverviewPerMainAccount(selectedRiskTypeMarginRequirements)

    const seivedMarginRequirements = _.sortBy(selectedRiskTypeMarginRequirements, [
        _margin => {
            return riskType === RISK_TYPES.POSITION_EFFECTIVE_RATIO.key ? Math.abs(_margin.riskDetail?.effective_ratio)
                : riskType === RISK_TYPES.ACCOUNT_MMR.key ? Number(_margin.riskDetail?.mmr)
                : riskType === RISK_TYPES.CREDIT_MMR.key ? Number(_margin.riskDetail?.mrr)
                : null
        }
    ])

    const areCreditParamsValid = _.every(_.keys(creditMarginInfoPerMainAccount), _mainAccount => {
        const _creditExchange = _getCreditExchange(_mainAccount)
        return BigNumber(creditTargets[_creditExchange] ?? DEFAULT_CREDIT_TARGET).lte(creditLimits[_creditExchange] ?? DEFAULT_CREDIT_LIMIT)
    })

    const areParamsValid = BigNumber(effectiveRatioTarget || 0).lte(effectiveRatioLimit || 0)
        && BigNumber(MMRTarget || 0).lte(MMRLimit || 0)
        && areCreditParamsValid

    let _pricePerCoin = {}
    const _getTokenPrice = (_coin) => {
        return _pricePerCoin[_coin] ?? getTokenPriceInUSD(_coin, pricings)
    }

    const _syncAccountBalances = (transfers=[]) => {
        const _accounts = {}
        _.forEach(transfers, _transfer => {
            const { originAccount, originBalanceType, destinationAccount, destinationBalanceType } = _transfer
            if (!_.has(_accounts, originAccount)) {
                _accounts[originAccount] = {
                    name: originAccount,
                    types: []
                }
            }
            if (!_.has(_accounts, destinationAccount)) {
                _accounts[destinationAccount] = {
                    name: destinationAccount,
                    types: []
                }
            }
            _accounts[originAccount].types.push(`${_.toLower(originBalanceType)}_account_balance`)
            _accounts[destinationAccount].types.push(`${_.toLower(destinationBalanceType)}_account_balance`)
        })
        if (!_.isEmpty(_accounts)) {
            _.forEach(_accounts, _account => {
                _account.types = _.concat(_.uniq(_account.types), ['balance'])
            })
            dispatch(syncAccountBalances(Object.values(_accounts)))
        }

        setTimeout(() => {
            dispatch(fetchLiquidationRatio())
        }, 2000)
    }

    const handleClickFetchXMarginButon = useEvent(() => {
        if (!isFetchingLiquidationRatio) {
            setIsFetchingLiquidationRatio(true)

            dispatch(fetchLiquidationRatio())
            .finally(() => {
                if (isMounted()) {
                    setIsFetchingLiquidationRatio(false)
                }
            })
        }
    })

    const handleClickGenerateAutoMarginAllocation = useEvent(() => {
        const _marginAllocation = _generateMarginAllocation({
            marginRequirements: seivedMarginRequirements,
            marginCollections: selectedAccountsMarginCollections,
            creditMarginInfoPerMainAccount,
            maxWithdrawableBalanceRate
        })
        setMarginAllocation({
            ..._marginAllocation,
            key: marginAllocation.key + 1,
            title: `Auto Margin Allocation`
        })
    })

    const handleClickFulfilRequiredMargin = useEvent((mainAccountName) => {
        const _marginAllocation = _generateMarginAllocation({
            marginRequirements: _.filter(seivedMarginRequirements, { mainAccountName }),
            marginCollections: selectedAccountsMarginCollections,
            creditMarginInfoPerMainAccount,
            maxWithdrawableBalanceRate
        })
        setMarginAllocation({
            ..._marginAllocation,
            key: marginAllocation.key + 1,
            title: `Fulfill Margin - ${mainAccountName}`
        })
    })

    const handleClickCollectMainAccountMargin = useEvent((mainAccountName, coin) => {
        const newMarginAllocation = MarginAllocationStruct({
            key: marginAllocation.key + 1,
            title: `Collect ${coin} - ${mainAccountName} `
        })

        const transfers = []
        let _remainingCreditWithdrawableMargin = _.get(creditMarginInfoPerMainAccount, `${mainAccountName}.withdrawableMargin`)

        _.forEach(selectedAccountsMarginCollections, _collection => {
            const { exchangeName, accountName: originAccount, mainAccountName: _mainAccount, withdrawableBalances, riskType: _riskType, riskAdjustedMarginDelta } = _collection
            if (_mainAccount === mainAccountName) {
                let _remainingRiskAdjustedMarginDelta = riskAdjustedMarginDelta
                const destinationBalanceType = _.get(CONFIG_PER_EXCHANGE, `${exchangeName}.marginPool.balanceType`)
                _.forEach(withdrawableBalances, _withdrawable => {
                    const { coin: _coin, balanceType: originBalanceType } = _withdrawable
                    if (_coin === coin) {
                        const { withdrawalPerCoin, remainingRiskAdjustedMargin, remainingCreditWithdrawableMargin } = calculateWithdrawals({
                            withdrawableBalances: [_withdrawable],
                            riskAdjustedMarginDelta: _remainingRiskAdjustedMarginDelta,
                            creditWithdrawableMargin: _remainingCreditWithdrawableMargin,
                            riskType: _riskType,
                            maxWithdrawableBalanceRate
                        }) 

                        const { withdrawableAmount: cappedWithdrawableAmount, withdrawableMargin: cappedWithdrawableMargin } = withdrawalPerCoin[coin]
                        if (BigNumber(cappedWithdrawableAmount).gt(0)) {
                            if (originAccount !== mainAccountName || originBalanceType !== destinationBalanceType) {
                                const originAccountType = _.get(CONFIG_PER_EXCHANGE, `${exchangeName}.accountTypePerBalance.${originBalanceType}`)
                                const destinationAccountType = _.get(CONFIG_PER_EXCHANGE, `${exchangeName}.accountTypePerBalance.${destinationBalanceType}`)
                                transfers.push(TransferStruct({
                                    originAccount,
                                    originBalanceType,
                                    originAccountType,
                                    destinationAccount: mainAccountName,
                                    destinationBalanceType,
                                    destinationAccountType,
                                    coin,
                                    amount: cappedWithdrawableAmount
                                }))
                            }

                            _remainingRiskAdjustedMarginDelta = remainingRiskAdjustedMargin
                            _remainingCreditWithdrawableMargin = remainingCreditWithdrawableMargin
                            newMarginAllocation.totalCollectibleMargin = BigNumber(newMarginAllocation.totalCollectibleMargin).plus(cappedWithdrawableMargin).toString()
                        }
                    }
                })
            }
        })

        newMarginAllocation.intraMainAccountTransfers = transfers
        setMarginAllocation(newMarginAllocation)
    })

    const handleTransfer = useEvent(async (allocationKey='', transferPath='', shouldSyncBalanceOnFinish=false) => {
        const _transfer = _.get(marginAllocation, transferPath) || {}
        const { originAccount, originAccountType, destinationAccount, destinationAccountType, coin, amount, status } = _transfer
        const originAccountTypeValue = ACCOUNT_TYPES[originAccountType]?.value
        const destinationAccountTypeValue = ACCOUNT_TYPES[destinationAccountType]?.value

        let result
        if (areAllValuesNonEmpty([originAccount, originAccountType, destinationAccount, destinationAccountType, coin, amount])
        && [TRANSFER_STATUS.READY, TRANSFER_STATUS.PENDING, TRANSFER_STATUS.FAILED].includes(status)) {
            setMarginAllocation(prevMarginAllocation => {
                return dotProp.merge(prevMarginAllocation, transferPath, {
                    status: TRANSFER_STATUS.TRANSFERRING,
                    message: ''
                })
            })
            dispatch(accountTransferFund({
                account_name: originAccount,
                from: originAccountTypeValue,
                to_account_name: destinationAccount,
                to: destinationAccountTypeValue,
                currency: coin,
                amount: Number(amount)
            }))
            .then(async (response) => {
                const status = response?.status
                if (status) {
                    const message = await response.text()
                    if (status === 200) {
                        return message
                    } else {
                        throw new Error(`Status code: ${status}, ${message || 'no error message returend.'}`)
                    }
                } else {
                    throw new Error('Missing response status code')
                }
            })
            .then((message) => {
                result = message
                if (isMounted() && latestMarginAllocationKey.current === allocationKey) {
                    setMarginAllocation(prevMarginAllocation => {
                        return dotProp.merge(prevMarginAllocation, transferPath, {
                            status: TRANSFER_STATUS.SUCCESS,
                            message
                        })
                    })
                }
            })
            .catch((error) => {
                result = error
                console.error(`PortfolioGuard handleTransfer(${allocationKey}, ${transferPath}) error: `, error)
                if (isMounted() && latestMarginAllocationKey.current === allocationKey) {
                    setMarginAllocation(prevMarginAllocation => {
                        return dotProp.merge(prevMarginAllocation, transferPath, {
                            status: TRANSFER_STATUS.FAILED,
                            message: error.message
                        })
                    })
                }
            })
            .finally(() => {
                if (shouldSyncBalanceOnFinish) {
                    _syncAccountBalances([_transfer])
                }
            })
        }

        return result
    })

    const handleClickBulkTransfer = useEvent(async (bulkTransfeType='') => {
        const allocationKey = marginAllocation.key
        const _transfersPath = BULK_TRANSFER_TYPES.INTRA_MAIN_ACCOUNT_TRANSFERS ? `intraMainAccountTransfers`
            : BULK_TRANSFER_TYPES.INTER_MAIN_ACCOUNT_COLLECTIONS ? `interMainAccountTransfer.collections`
            : BULK_TRANSFER_TYPES.INTER_MAIN_ACCOUNT_DISTRIBUTIONS ? `interMainAccountTransfer.distributions`
            : null

        if (bulkTransfeType === BULK_TRANSFER_TYPES.INTRA_MAIN_ACCOUNT_TRANSFERS && !isTransferringIntraMainAccountTransfers) {
            setIsTransferringIntraMainAccountTransfers(true)
            latestIsTransferringIntraMainAccountTransfers.current = true
        } else if (bulkTransfeType === BULK_TRANSFER_TYPES.INTER_MAIN_ACCOUNT_COLLECTIONS && !isTransferringInterMainAccountCollections) {
            setIsTransferringInterMainAccountCollections(true)
            latestIsTransferringInterMainAccountCollections.current = true
        } else if (bulkTransfeType === BULK_TRANSFER_TYPES.INTER_MAIN_ACCOUNT_DISTRIBUTIONS && !isTransferringInterMainAccountDistributions) {
            setIsTransferringInterMainAccountDistributions(true)
            latestIsTransferringInterMainAccountDistributions.current = true
        } else {
            return
        }

        let transfers = _.get(marginAllocation, _transfersPath)
        if (!_.isEmpty(transfers)) {

            // Update transfers status
            setMarginAllocation(prevAllocation => {
                const _newTransfers = _.cloneDeep(_.get(prevAllocation, _transfersPath))
                _.forEach(_newTransfers, _transfer => {
                    if ([TRANSFER_STATUS.READY, TRANSFER_STATUS.FAILED].includes(_transfer.status)) {
                        _transfer.status = TRANSFER_STATUS.PENDING
                        _transfer.message = ''
                    }
                })
                return dotProp.set(prevAllocation, _transfersPath, _newTransfers)
            })
    
            // Added index onto transfer before grouping by exchange
            transfers = _.map(transfers, (_transfer, _index) => ({
                ..._transfer,
                originalIndex: _index
            }))
            const _transfersPerExchange = _.groupBy(transfers, _transfer => _.get(accountItems, `${_transfer.originAccount}.exchange_name`))

            for (const [_exchange, _transfers] of Object.entries(_transfersPerExchange)) {
                const interval = TRANSFER_INTERVAL_PER_EXCHANGE[_exchange] ?? 0
                let lastHandleTime = 0
            
                for (const _transfer of _transfers) {
                    const now = moment().unix()
                    const delay = Math.max(0, lastHandleTime + interval - now)
            
                    // Wait until the interval condition is met
                    if (delay > 0) {
                        await new Promise(resolve => setTimeout(resolve, delay))
                    }

                    if (latestMarginAllocationKey.current !== allocationKey
                        || (bulkTransfeType === BULK_TRANSFER_TYPES.INTRA_MAIN_ACCOUNT_TRANSFERS && !latestIsTransferringIntraMainAccountTransfers.current)
                        || (bulkTransfeType === BULK_TRANSFER_TYPES.INTER_MAIN_ACCOUNT_COLLECTIONS && !latestIsTransferringInterMainAccountCollections.current)
                        || (bulkTransfeType === BULK_TRANSFER_TYPES.INTER_MAIN_ACCOUNT_DISTRIBUTIONS && !latestIsTransferringInterMainAccountDistributions.current)
                    ) {
                        break
                    } else {
                        // handle the transfer
                        lastHandleTime = now
                        await handleTransfer(allocationKey, `${_transfersPath}.${_transfer.originalIndex}`, false)
                    }
                }
            }
            _syncAccountBalances(transfers)
        }

        if (latestMarginAllocationKey.current === allocationKey) {
            if (bulkTransfeType === BULK_TRANSFER_TYPES.INTRA_MAIN_ACCOUNT_TRANSFERS) {
                setIsTransferringIntraMainAccountTransfers(false)
            } else if (bulkTransfeType === BULK_TRANSFER_TYPES.INTER_MAIN_ACCOUNT_COLLECTIONS) {
                setIsTransferringInterMainAccountCollections(false)
            } else if (bulkTransfeType === BULK_TRANSFER_TYPES.INTER_MAIN_ACCOUNT_DISTRIBUTIONS) {
                setIsTransferringInterMainAccountDistributions(false)
            }
        }
    })

    function Portfolios () {
        return (
            <div className='portfolio-guard--portfolios'>
                <label>{'Porfolios'}</label>
                <div className='portfolio-guard--portfolios--list'>
                    {_.map(allPortfolioNames, _portfolio => {
                        const _isSelected = portfolios.includes(_portfolio)
                        return (
                            <button className={_isSelected ? 'selected' : null}
                                key={_portfolio}
                                onClick={() => { setPortfolios(_isSelected ? _.without(portfolios, _portfolio) : _.concat(portfolios, [_portfolio])) }}>
                                {_portfolio}
                            </button>
                        )
                    })}
                </div>
            </div>
        )
    }

    function Credits (marginSide=MARGIN_SIDES.COLLECTION) {
        const _size = _.size(portfolioMarginVirtualAccountInfo)
        return (
            <Popup className='portfolio-guard--credits'
                trigger={
                    <button className={'portfolio-guard--credits--trigger' + (!areCreditParamsValid ? ' warning' : '')}
                        disabled={_size === 0}>
                        {`${_size} Exchange${_size > 1 ? 's' : ''}`}
                    </button>}
                on={'click'}
                disabled={_size === 0}>
                {_.map(_.keys(portfolioMarginVirtualAccountInfo), _accountName => {
                    const _creditExchange = _getCreditExchange(_accountName)
                    let value
                    let shouldShowWarning = false
                    if (marginSide === MARGIN_SIDES.COLLECTION) {
                        value = creditLimits[_creditExchange] ?? DEFAULT_CREDIT_LIMIT
                        shouldShowWarning = BigNumber(value).lt(creditTargets[_creditExchange] ?? DEFAULT_CREDIT_TARGET)
                    } else if (marginSide === MARGIN_SIDES.REQUIREMENT) {
                        value = creditTargets[_creditExchange] ?? DEFAULT_CREDIT_TARGET
                        shouldShowWarning = BigNumber(value).gt(creditLimits[_creditExchange] ?? DEFAULT_CREDIT_LIMIT)
                    }
                    return (
                        <div className='portfolio-guard--credits--item' key={_creditExchange}>
                            <label>{_creditExchange}</label>
                            <input
                                style={{
                                    background: shouldShowWarning ? '#eeda85' : null,
                                    color: shouldShowWarning ? '#041928' : null
                                }}          
                                type='number'
                                placeholder='1.0'
                                value={value}
                                onChange={(e) => {
                                    const _value = e.target.value
                                    if (Number(_value) >= 0) {
                                        if (marginSide === MARGIN_SIDES.COLLECTION) {
                                            setCreditLimits({
                                                ...creditLimits,
                                                [_creditExchange]: _value
                                            })
                                        } else if (marginSide === MARGIN_SIDES.REQUIREMENT) {
                                            setCreditTargets({
                                                ...creditTargets,
                                                [_creditExchange]: _value
                                            })
                                        }
                                    }
                                }} />
                        </div>
                    )
                })}
            </Popup>
        )
    }

    function RiskDetail (_riskType, _riskDetail={}, _portfolioCurrency='USD') {
        return (
            <div className='portfolio-guard--risk-detail'>
                <div className='portfolio-guard--risk-detail--head'>
                    <label style={{ background: RISK_TYPE_COLORS[_riskType] }}>{RISK_TYPES[_riskType]?.name}</label>
                    <span>
                        {_riskType === RISK_TYPES.POSITION_EFFECTIVE_RATIO.key ? moment(_riskDetail.effective_updated).format('HH:mm:ss')
                            : [RISK_TYPES.ACCOUNT_MMR.key, RISK_TYPES.CREDIT_MMR.key].includes(_riskType) ? moment(_riskDetail.timestamp).format('HH:mm:ss')
                                : null}
                    </span>
                </div>
                <div className='portfolio-guard--risk-detail--stats'>
                    {_riskType === RISK_TYPES.POSITION_EFFECTIVE_RATIO.key ? <>
                        <div>
                            <label>{'X Margin Group'}</label>
                            <span>{_.replace(_riskDetail?.cross_margin_group, /_/g, ' ')}</span>
                        </div>
                        <div>
                            <label>{'Adjusted Position'}</label>
                            <span>{`$${BigNumber(_riskDetail?.adjusted_position_usd).toFormat(0, 1)}`}</span>
                        </div>
                        <div>
                            <label>{'Effecitve Ratio'}</label>
                            <span>{`${BigNumber(_riskDetail?.effective_ratio).times(100).toFormat(2, 1)}%`}</span>
                        </div>
                    </> : [RISK_TYPES.ACCOUNT_MMR.key, RISK_TYPES.CREDIT_MMR.key].includes(_riskType) ? <>
                        <div>
                            <label>{'Equity'}</label>
                            <span>
                                {_portfolioCurrency === 'USD' ? `$${BigNumber(_riskDetail?.equity).toFormat(0, 1)}`
                                    : `${formattedTokenAmount({ amount: _riskDetail?.equity })} ${_portfolioCurrency}`}
                            </span>
                        </div>
                        <div>
                            <label>{'Maint. Margin'}</label>
                            <span>
                                {_portfolioCurrency === 'USD' ? `$${BigNumber(_riskDetail?.maint_margin).toFormat(0, 1)}`
                                    : `${formattedTokenAmount({ amount: _riskDetail?.maint_margin })} ${_portfolioCurrency}`}
                            </span>
                        </div>
                        <div>
                            <label>{'MMR'}</label>
                            <span>{`${BigNumber(_riskDetail?.mmr).times(100).toFormat(2, 1)}%`}</span>
                        </div>
                    </> : null}
                </div>
            </div>
        )
    }

    function MarginRequirements () {

        const _totalRequiredMarginPerRiskType = _.reduce(marginRequirementsPerRiskType, (_result, _marginRequirements, _riskType) => {
            _result[_riskType] = BigNumber.sum(..._.map(_marginRequirements, _margin => _margin.riskAdjustedMarginDelta)).toString()
            return _result
        }, {})

        const _sortedRequirementOverview = _.sortBy(requirementOverviewPerMainAccount, _requirement => -Number(_requirement.totalRequiredMargin))
        const _seivedMarginRequirements = _.filter(seivedMarginRequirements, _margin => {
            const { accountName: _account, acceptableAssets: _acceptable } = _margin
            return isMetSearchStringCriteria(`${_account} ${_.concat(_acceptable?.balanceTypes, _acceptable?.coins).join(' ')}`, requirementSearchString)
        })

        return (
            <div className='portfolio-guard--margin-requirements'>
                <button className='portfolio-guard--margin-requirements--params-toggle requirement' onClick={() => { setShouldShowRequirementParams(!shouldShowRequirementParams) }}>
                    <span>{'Margin Requirement Parameters'}</span>
                    {shouldShowRequirementParams ? <FaCaretDown /> : <FaCaretLeft />}
                </button>
                {shouldShowRequirementParams &&
                <div className='portfolio-guard--margin-requirements--params'>
                    <div>
                        <Popup className='portfolio-guard--margin-requirements--params--tooltip'
                            trigger={<label>{'X-Margin Eff. Ratio Target'}</label>}>
                            {`When the effective ratio of a cross-margin group falls below the set value, additional margin will be calculated. 
                            The required margin is expected to bring the effective ratio back to the specified value.`}
                        </Popup>
                        <input className={areAllValuesNonEmpty([effectiveRatioTarget, effectiveRatioLimit]) && BigNumber(effectiveRatioTarget).gt(effectiveRatioLimit) ? 'warning' : null}
                            type={'number'}
                            placeholder='0.2'
                            min={0}
                            value={effectiveRatioTarget}
                            onChange={(e) => {
                                const _value = e.target.value
                                if (Number(_value) >= 0) {
                                    setEffectiveRatioTarget(_value)
                                }
                            }} />
                    </div>
                    <div>
                        <Popup className='portfolio-guard--margin-requirements--params--tooltip'
                            trigger={<label>{'PMA MMR Target'}</label>}>
                            {`When the MMR of a portfolio margin account falls below the set value, additional margin will be calculated. 
                            The required margin is expected to bring the MMR back to the specified value.`}
                        </Popup>
                        <input className={areAllValuesNonEmpty([MMRTarget, MMRLimit]) && BigNumber(MMRTarget).gt(MMRLimit) ? 'warning' : null}
                            type={'number'}
                            placeholder='2.0'
                            min={0}
                            value={MMRTarget}
                            onChange={(e) => {
                                const _value = e.target.value
                                if (Number(_value) >= 0) {
                                    setMMRTarget(_value)
                                }
                            }} />
                    </div>
                    <div>
                        <Popup className='portfolio-guard--margin-requirements--params--tooltip'
                            trigger={<label>{'Credit MMR Targets'}</label>}>
                            {`When the credit MMR for an exchange falls below the set value, additional margin will be calculated. 
                            The required margin is expected to restore the MMR to the specified value`}
                        </Popup>
                        {Credits(MARGIN_SIDES.REQUIREMENT)}
                    </div>
                </div>}
                <div className='portfolio-guard--margin-requirements--risk-types'>
                    {_.map(RISK_TYPES, _r => {
                        const { key: _riskType, name: _riskName } = _r
                        const  _totalRequiredMargin = _totalRequiredMarginPerRiskType[_riskType]
                        return _riskType !== RISK_TYPES.RISK_FREE_BALANCE.key && (
                            <button key={_riskType}
                                className={_riskType === riskType ? 'selected' : null}
                                onClick={() => { setRiskType(_riskType) }}>
                                <label>{_riskName}</label>
                                {!_.isEmpty(_totalRequiredMargin) && <span>{`$${toAbbreviateNumber(_totalRequiredMargin, 0)}`}</span>}
                            </button>
                        )
                    })}
                </div>
                <div className='portfolio-guard--margin-requirements--overview'
                    style={{
                        flexGrow: shouldShowRequirementOverview && !shouldShowRequirementDetail ? 1 : null,
                        height: shouldShowRequirementOverview && !shouldShowRequirementDetail ? 0 : null,
                        maxHeight: shouldShowRequirementOverview && shouldShowRequirementDetail ? '40%' : null
                    }}>
                    <button className='portfolio-guard--margin-requirements--overview--toggle' onClick={() => { setShouldShowRequirementOverview(!shouldShowRequirementOverview) }}>
                        <span>{'Required Margin Overview'}</span>
                        {shouldShowRequirementOverview ? <FaCaretDown /> : <FaCaretLeft />}
                    </button>
                    {shouldShowRequirementOverview &&
                    <div className='portfolio-guard--margin-requirements--overview--list'>
                        {_.map(_sortedRequirementOverview, _mainAccountRequirement => {
                            const _mainAccount = _mainAccountRequirement.mainAccountName
                            const _sortedCoinsRequirement = _.sortBy(_mainAccountRequirement.requirementPerCoins, _requirement => -Number(_requirement.totalRequiredMargin))
                            return (
                                <div className='portfolio-guard--margin-requirements--overview--main-account' key={_mainAccount}>
                                    <div className='portfolio-guard--margin-requirements--overview--main-account--head'>
                                        <label>{'Main Acct'}</label>
                                        <div>{_mainAccount}</div>
                                        <span>{`$${BigNumber(_mainAccountRequirement.totalRequiredMargin).toFormat(0, 1)}`}</span>
                                    </div>
                                    <div className='portfolio-guard--margin-requirements--overview--main-account--coins'>
                                        {_.map(_sortedCoinsRequirement, _requirement => {
                                            const { acceptableCoins } = _requirement
                                            return (
                                                <div className='portfolio-guard--margin-requirements--overview--main-account--coins--item' key={acceptableCoins}>
                                                    {_.isEmpty(acceptableCoins) ? <div className='portfolio-guard--margin-requirements--overview--main-account--coins--item--empty-assets'>{'Unkown Coins'}</div>
                                                    : _.map(acceptableCoins.split(','), _coin => {
                                                        return (
                                                            <div className='portfolio-guard--margin-requirements--overview--coin' key={_coin}
                                                                style={{ backgroundColor: COIN_COLORS[_coin] }}>
                                                                {_coin}
                                                            </div>
                                                        )
                                                    })}
                                                    <span>{`$${BigNumber(_requirement.totalRequiredMargin).toFormat(0, 1)}`}</span>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                            )
                        })}
                    </div>}
                </div>
                <div className='portfolio-guard--margin-requirements--detail'
                    style={{
                        height: shouldShowRequirementDetail ? 0 : null,
                        flexGrow: shouldShowRequirementDetail ? 1 : null
                    }}>
                    <button className='portfolio-guard--margin-requirements--detail--toggle' onClick={() => { setShouldShowRequirementDetail(!shouldShowRequirementDetail) }}>
                        <span>{`Detail (${_.size(_seivedMarginRequirements)})`}</span>
                        {shouldShowRequirementDetail ? <FaCaretDown /> : <FaCaretLeft />}
                    </button>
                    {shouldShowRequirementDetail &&
                    <>
                        <input className='portfolio-guard--margin-requirements--detail--search-input'
                            placeholder='Search Account, Balance Type, Coin'
                            spellCheck={false}
                            value={requirementSearchString}
                            onChange={(e) => { setRequirementSearchString(e.target.value) }} />
                        <div className='portfolio-guard--margin-requirements--detail--list'>
                            {_.map(_seivedMarginRequirements, (_margin, _index) => {
                                const { accountName, riskDetail, riskAdjustedMarginDelta, acceptableAssets, portfolioCurrency } = _margin
                                return (
                                    <div className='portfolio-guard--margin-requirements--item' key={_index}>
                                        <div className='portfolio-guard--margin-requirements--item--overview'>
                                            <div className='portfolio-guard--margin-requirements--item--account-name'>{accountName}</div>
                                            <div className='portfolio-guard--margin-requirements--item--value-usd'>{`$${BigNumber(riskAdjustedMarginDelta).toFormat(0)}`}</div>
                                        </div>
                                        {_.isEmpty(acceptableAssets?.balanceTypes) ? <div className='portfolio-guard--margin-requirements--item--acceptable--emtpy-assets'>{'No Assets Configured'}</div>
                                            : <div className='portfolio-guard--margin-requirements--item--acceptable'>
                                                <div className='portfolio-guard--margin-requirements--item--acceptable--balance-types'>
                                                    {_.map(acceptableAssets.balanceTypes, _balanceType => (
                                                        <span className={`portfolio-guard--margin-requirements--item--acceptable--balance-type ${_balanceType}`}
                                                            style={{ background: BALANCE_TYPE_COLORS[_balanceType] }}
                                                            key={_balanceType}>{_balanceType}</span>
                                                    ))}
                                                </div>
                                                <div className='portfolio-guard--margin-requirements--item--acceptable--coins'>
                                                    {_.map(acceptableAssets?.coins, _coin => (
                                                        <span className={`portfolio-guard--margin-requirements--item--acceptable--coin ${_coin}`}
                                                            key={_coin}
                                                            style={{ background: COIN_COLORS[_coin] }}>
                                                            {_coin}
                                                        </span>
                                                    ))}
                                                </div>
                                            </div>}
                                        {riskType !== RISK_TYPES.RISK_FREE_BALANCE.key && !_.isEmpty(riskDetail) && RiskDetail(riskType, riskDetail, portfolioCurrency)}
                                    </div>
                                )
                            })}
                        </div>
                    </>}
                </div>
            </div>
        )
    }

    function MarginCollections () {
        const _sortedCollectionOverview = _.sortBy(collectionOverviewPerMainAccount, _mainAccountCollection => -Number(_mainAccountCollection.totalCollectibleMargin))
        const _seivedMarginCollections = _.sortBy(
            _.filter(selectedAccountsMarginCollections, _margin => {
                const { riskType: _riskType, accountName: _accountName, mainAccountName: _mainAccount, withdrawableBalances: _withdrawableBalances, riskAdjustedMarginDelta: _riskAdjustedMarginDelta } = _margin
                
                const { totalWithdrawableMargin } = calculateWithdrawals({
                    withdrawableBalances: _withdrawableBalances,
                    riskAdjustedMarginDelta: _riskAdjustedMarginDelta,
                    creditWithdrawableMargin: creditMarginInfoPerMainAccount[_mainAccount]?.withdrawableMargin,
                    riskType: _riskType,
                    maxWithdrawableBalanceRate
                })
    
                const _coins = _.uniq(_.map(_withdrawableBalances, _withdrawable => _withdrawable.coin))
                const _balanceTypes = _.uniq(_.map(_withdrawableBalances, _withdrawable => _withdrawable.balanceType))
                return BigNumber(totalWithdrawableMargin).gt(0) && isMetSearchStringCriteria(`${_accountName} ${_coins.join(' ')} ${_balanceTypes.join(' ')}`, collectionSearchString)
            }),
            _margin => {
                const { riskType: _riskType, mainAccountName: _mainAccount, withdrawableBalances: _withdrawableBalances, riskAdjustedMarginDelta: _riskAdjustedMarginDelta } = _margin
                const { totalWithdrawableMargin } = calculateWithdrawals({
                    withdrawableBalances: _withdrawableBalances,
                    riskAdjustedMarginDelta: _riskAdjustedMarginDelta,
                    creditWithdrawableMargin: creditMarginInfoPerMainAccount[_mainAccount]?.withdrawableMargin,
                    riskType: _riskType,
                    maxWithdrawableBalanceRate
                })
                return -Number(totalWithdrawableMargin)
            }
        )
        const _oneMinuteAgo = moment().add(-1, 'minutes')

        return (
            <div className='portfolio-guard--margin-collections'>
                <button className='portfolio-guard--margin-collections--params-toggle collection' onClick={() => { setShouldShowCollectionParams(!shouldShowCollectionParams) }}>
                    <span>{'Margin Collection Parameters'}</span>
                    {shouldShowCollectionParams ? <FaCaretDown /> : <FaCaretLeft />}
                </button>
                {shouldShowCollectionParams &&
                <>
                    <div className='portfolio-guard--margin-collections--params'>
                        <div>
                            <Popup className='portfolio-guard--margin-collections--params--tooltip'
                                trigger={<label>{'X-Margin Eff. Ratio Limit'}</label>}>
                                {`Only margin from cross-margin group accounts with an effective ratio above this value can be withdrawn, 
                                ensuring the effective ratio remains above this value after the withdrawal.`}
                            </Popup>
                            <input className={areAllValuesNonEmpty([effectiveRatioLimit, effectiveRatioTarget]) && BigNumber(effectiveRatioLimit).lt(effectiveRatioTarget) ? 'warning' : null}
                                type={'number'}
                                placeholder='0.24'
                                min={0}
                                value={effectiveRatioLimit}
                                onChange={(e) => {
                                    const _value = e.target.value
                                    if (Number(_value) >= 0) {
                                        setEffectiveRatioLimit(_value)
                                    }
                                }} />
                        </div>
                        <div>
                            <Popup className='portfolio-guard--margin-collections--params--tooltip'
                                trigger={<label>{'PMA MMR Limit'}</label>}>
                                {`Only margin from portfolio margin accounts with an MMR above this value can be withdrawn, 
                                ensuring the MMR remains above this value after the withdrawal.`}
                            </Popup>
                            <input className={areAllValuesNonEmpty([MMRLimit, MMRTarget]) && BigNumber(MMRLimit).lt(MMRTarget) ? 'warning' : null}
                                type={'number'}
                                placeholder='2.5'
                                min={0}
                                value={MMRLimit}
                                onChange={(e) => {
                                    const _value = e.target.value
                                    if (Number(_value) >= 0) {
                                        setMMRLimit(_value)
                                    }
                                }} />
                        </div>
                        <div>
                            <Popup className='portfolio-guard--margin-collections--params--tooltip'
                                trigger={<label>{'Credit MMR Limits'}</label>}>
                                {`Only margin from exchange accounts with a credit MMR above this value can be withdrawn,
                                ensuring the MMR stays above this value after the withdrawal.`}
                            </Popup>
                            {Credits(MARGIN_SIDES.COLLECTION)}
                        </div>
                        <div>
                            <Popup className='portfolio-guard--margin-collections--params--tooltip'
                                trigger={<label>{'Max Withdrawable Balance Rate'}</label>}>
                                {`Except for risk-free balance types, which can withdraw the full available balance, 
                                all other accounts can only withdraw a percentage of their available balance, not exceeding this set value.`}
                            </Popup>
                            <input type={'number'}
                                min={0}
                                value={maxWithdrawableBalanceRate}
                                onChange={(e) => {
                                    const _value = e.target.value
                                    if (Number(_value) >= 0 && Number(_value) <= 1) {
                                        setMaxWithdrawableBalanceRate(_value)
                                    }
                                }} />
                        </div>
                    </div>
                    <div className='portfolio-guard--margin-collections--accounts-and-coins'>
                        <div className='portfolio-guard--margin-collections--accounts'>
                            <label>{'Accounts'}</label>
                            <PortfolioAccountSelector
                                accountItemOptions={_.pick(accountItems, selectedPortfoliosAccounts)} 
                                selectedAccountNames={marginCollectonAccounts}
                                onChangeSelectedAccountNames={(newAccountNames) => { setMarginCollectionAccounts(newAccountNames) }} />
                        </div>
                        <div className='portfolio-guard--margin-collections--coins'>
                            <label>{'Coins'}</label>
                            <div className='portfolio-guard--margin-collections--coins--list'>
                                {_.map(TRANSFERABLE_COINS, _coin => {
                                    const _isSelected = transferableCoins.includes(_coin)
                                    return (
                                        <button key={_coin}
                                            className={_isSelected ? 'selected' : null}
                                            style={{ background: COIN_COLORS[_coin] }}
                                            onClick={() => { setTransferableCoins(_isSelected ? _.without(transferableCoins, _coin) : _.concat(transferableCoins, _coin)) }}>
                                            {_coin}
                                        </button>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                </>}
                <div className='portfolio-guard--margin-collections--overview'
                    style={{
                        flexGrow: shouldShowCollectionOverview && !shouldShowCollectionDetail ? 1 : null,
                        height: shouldShowCollectionOverview && !shouldShowCollectionDetail ? 0 : null,
                        maxHeight: shouldShowCollectionOverview && shouldShowCollectionDetail ? '45%' : null
                    }}>
                    <button className='portfolio-guard--margin-collections--overview--toggle' onClick={() => { setShouldShowCollectionOverview(!shouldShowCollectionOverview) }}>
                        <span>{'Collectible Margin Overview'}</span>
                        {shouldShowCollectionOverview ? <FaCaretDown /> : <FaCaretLeft />}
                    </button>
                    {shouldShowCollectionOverview && <div className='portfolio-guard--margin-collections--overview--list'>
                        {_.map(_sortedCollectionOverview, (_mainAccountCollection) => {
                            const { mainAccountName: _mainAccount, marginInfoPerCoin: _marginInfoPerCoin, creditMarginInfo: _creditMarginInfo } = _mainAccountCollection
                            const _mainAccountTotalCollectibleMargin = BigNumber(_mainAccountCollection.totalCollectibleMargin)
                            const _sortedCoinMarginInfos = _.sortBy(_marginInfoPerCoin, _coinMarginInfo => -Number(_coinMarginInfo.totalCollectibleMargin))
                            return _mainAccountTotalCollectibleMargin.gt(0) && (
                                <div className='portfolio-guard--margin-collections--overview--main-account' key={_mainAccount}>
                                    <div className='portfolio-guard--margin-collections--overview--main-account--head'>
                                        <label>{'Main Acct'}</label>
                                        <div>{_mainAccount}</div>
                                        <span>{`$${_mainAccountTotalCollectibleMargin.toFormat(0, 1)}`}</span>
                                    </div>
                                    <div className='portfolio-guard--margin-collections--overview--main-account--coins'>
                                        {_.map(_sortedCoinMarginInfos, _collection => {
                                            const { coin, totalCollectibleAmount: _coinAmount, totalCollectibleMargin: _coinMargin } = _collection
                                            return (
                                                <div className='portfolio-guard--margin-collections--overview--main-account--coin' key={coin}>
                                                    <label style={{ background: COIN_COLORS[coin] }}>{coin}</label>
                                                    <span>{`${formattedTokenAmount({ amount: _coinAmount })} ($${BigNumber(_coinMargin).toFormat(0, 1)})`}</span>
                                                </div>
                                            )
                                        })}
                                    </div>
                                    {!_.isEmpty(_creditMarginInfo) && RiskDetail(RISK_TYPES.CREDIT_MMR.key, _creditMarginInfo.creditDetail)}
                                </div>
                            )
                        })}
                    </div>}
                </div>
                <div className='portfolio-guard--margin-collections--detail'
                    style={{
                        height: shouldShowCollectionDetail ? 0 : null,
                        flexGrow: shouldShowCollectionDetail ? 1 : null
                    }}>
                    <button className='portfolio-guard--margin-collections--detail--toggle' onClick={() => { setShouldShowCollectionDetail(!shouldShowCollectionDetail) }}>
                        <span>{`Detail (${_.size(_seivedMarginCollections)})`}</span>
                        {shouldShowCollectionDetail ? <FaCaretDown /> : <FaCaretLeft />}
                    </button>
                    {shouldShowCollectionDetail && <>
                        <input className='portfolio-guard--margin-collections--detail--search-input'
                            placeholder='Search Account, Balance Type, Coin'
                            spellCheck={false}
                            value={collectionSearchString}
                            onChange={(e) => { setCollectionSearchString(e.target.value) }} />
                        <div className='portfolio-guard--margin-collections--detail--list'>
                            {_.map(_seivedMarginCollections, (_margin, _index) => {
                                const { accountName, mainAccountName, riskType: _riskType, riskDetail, riskAdjustedMarginDelta, withdrawableBalances, portfolioCurrency } = _margin
                                const _sortedWithdrawableBalances = _.sortBy(withdrawableBalances, _withdrawable => {
                                    const { totalWithdrawableMargin: _totalMargin } = calculateWithdrawals({
                                        withdrawableBalances: [_withdrawable],
                                        riskAdjustedMarginDelta,
                                        riskType: _riskType,
                                        maxWithdrawableBalanceRate
                                    })
                                    return -Number(_totalMargin)
                                })
                                const { totalWithdrawableMargin } = calculateWithdrawals({
                                    withdrawableBalances,
                                    riskAdjustedMarginDelta,
                                    creditWithdrawableMargin: _.get(creditMarginInfoPerMainAccount, `${mainAccountName}.withdrawableMargin`),
                                    riskType: _riskType,
                                    maxWithdrawableBalanceRate
                                })
                                
                                return (
                                    <div className='portfolio-guard--margin-collections--item' key={_index}>
                                        <div className='portfolio-guard--margin-collections--item--overview'>
                                            <div className='portfolio-guard--margin-collections--item--account-name'>{accountName}</div>
                                            {_riskType === RISK_TYPES.RISK_FREE_BALANCE.key && <LuBadgeCheck className='portfolio-guard--margin-collections--item--risk-free' title='Risk Free Balance' />}
                                            <div className='portfolio-guard--margin-collections--item--value-usd'>{`$${BigNumber(totalWithdrawableMargin || 0).toFormat(0, 1)}`}</div>
                                        </div>
                                        {!_.isEmpty(_sortedWithdrawableBalances) ? <div className='portfolio-guard--margin-collections--item--withdrawables'>
                                            {_.map(_sortedWithdrawableBalances, (_withdrawable, _index) => {
                                                const { balanceType, balanceDetail, coin, withdrawableAmount, price, isCreditMargin } = _withdrawable
                                                const _withdrawableMargin = BigNumber(withdrawableAmount).times(price)
                                                const { withdrawalPerCoin } = calculateWithdrawals({
                                                    withdrawableBalances: [_withdrawable],
                                                    riskAdjustedMarginDelta,
                                                    creditWithdrawableMargin: _.get(creditMarginInfoPerMainAccount, `${mainAccountName}.withdrawableMargin`),
                                                    riskType: _riskType,
                                                    maxWithdrawableBalanceRate
                                                })
                                                const { withdrawableAmount: cappedWithdrawableAmount, withdrawableMargin: cappedWithdrawableMargin  } = withdrawalPerCoin[coin]
                                                const { timestamp: _timestamp } = balanceDetail || {}
                                                const _shouldTimestampWarning = _.isEmpty(_timestamp) || _oneMinuteAgo.isAfter(_timestamp)
                                                return (
                                                    <div className='portfolio-guard--margin-collections--item--withdrawables--item' key={_index}>
                                                        <div className='portfolio-guard--margin-collections--item--withdrawables--item--head'>
                                                            <div className='portfolio-guard--margin-collections--item--withdrawables--item--balance-type' style={{ background: BALANCE_TYPE_COLORS[balanceType] }}>{_.replace(balanceType, /_/g, ' ')}</div>
                                                            <div className='portfolio-guard--margin-collections--item--withdrawables--item--coin' style={{ background: COIN_COLORS[coin] }}>{coin}</div>
                                                            {isCreditMargin && <GiCreditsCurrency className='portfolio-guard--margin-collections--item--withdrawables--item--credit-margin-icon' />}
                                                            <div className={'portfolio-guard--margin-collections--item--withdrawables--item--balance-timestamp' + (_shouldTimestampWarning ? ' warning' : '')}>{moment(_timestamp).format('HH:mm:ss')}</div>
                                                        </div>
                                                        <div className='portfolio-guard--margin-collections--item--withdrawables--item--value capped-value'>
                                                            <label>{'Collectible'}</label>
                                                            <span>{`${formattedTokenAmount({ amount: cappedWithdrawableAmount })} ($${BigNumber(cappedWithdrawableMargin).toFormat(0, 1)})`}</span>
                                                        </div>
                                                        <div className='portfolio-guard--margin-collections--item--withdrawables--item--value available-value'>
                                                            <label>{'Available'}</label>
                                                            <span>{`${formattedTokenAmount({ amount: withdrawableAmount })} ($${_withdrawableMargin.toFormat(0, 1)})`}</span>
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </div> : <div className='portfolio-guard--margin-collections--item--emtpy-withdrawable'>{'No Withdrawable Found'}</div>}
                                        {_riskType !== RISK_TYPES.RISK_FREE_BALANCE.key && !_.isEmpty(riskDetail) && RiskDetail(_riskType, riskDetail, portfolioCurrency)}
                                    </div>
                                )
                            })}
                        </div>
                    </>}
                </div>
            </div>
        )
    }

    function Transfers () {
        const { key, title, totalRequiredMargin, totalCollectibleMargin, intraMainAccountTransfers, interMainAccountTransfer } = marginAllocation
        const _totalRequiredMargin = BigNumber(totalRequiredMargin || 0)
        const _totalCollectibleMargin = BigNumber(totalCollectibleMargin || 0)
        const _fulFilledRate = _totalCollectibleMargin.div(_totalRequiredMargin)

        // eslint-disable-next-line react/prop-types
        function TransferTableHeader ({ canTransfer=false, isTransfering=false, transfers=[], onClickTransfer=()=>{}, onClickDiscard=()=>{} }) {
            const _transferableSize = _.size(_.filter(transfers, _transfer => _transfer.status !== TRANSFER_STATUS.UNSUPPORTED))
            const _successSize = _.size(_.filter(transfers, _transfer => _transfer.status === TRANSFER_STATUS.SUCCESS))
            return (
                <thead>
                    <tr>
                        <th>{'Origin'}</th>
                        <th>{'Token'}</th>
                        <th>{'Destination'}</th>
                        {canTransfer &&
                        <>
                            <th>
                                {isTransfering ? <div className='portfolio-guard--bulk-transferring'>
                                    <span>{`${_successSize}/${_transferableSize}`}</span>
                                    <button onClick={() => { onClickDiscard() }}>{'Discard'}</button>
                                </div>
                                    : <button className='portfolio-guard--bulk-transfer-button'
                                        disabled={_successSize === _transferableSize}
                                        onClick={() => { onClickTransfer() }}>
                                        {`Transfer All (${_transferableSize - _successSize})`}
                                    </button>}

                            </th>
                            <th>{'Message'}</th>
                        </>}
                    </tr>
                </thead>
            )
        }

        // eslint-disable-next-line react/prop-types
        const Transfer = ({ transfer=TransferStruct({}), canTransfer=false, onClickTransfer=()=>{} }) => {
            const { originAccount, originBalanceType, originAccountType, destinationAccount, destinationBalanceType, destinationAccountType, coin, amount, status, message } = transfer
            const _margin = BigNumber(amount).times(_getTokenPrice(coin)).toFormat(0, 1)
            return (
                <tr className='portfolio-guard--transfer'>
                    <td>
                        <div className='portfolio-guard--transfer--account'>
                            <label>{originAccount}</label>
                            <span style={{ background: BALANCE_TYPE_COLORS[originBalanceType] }}>
                                {_.toUpper(_.get(ACCOUNT_TYPES, `${originAccountType}.name`, originBalanceType))}
                            </span>
                        </div>
                    </td>
                    <td>
                        <div className='portfolio-guard--transfer--token'>
                            <label style={{ background: COIN_COLORS[coin] }}>{coin}</label>
                            <div>{BigNumber(amount).toFormat()}</div>
                            <span>{`$(~$${_margin})`}</span>
                        </div>
                    </td>
                    <td>
                        <div className='portfolio-guard--transfer--account'>
                            <label>{destinationAccount}</label>
                            <span style={{ background: BALANCE_TYPE_COLORS[destinationBalanceType] }}>
                                {_.toUpper(_.get(ACCOUNT_TYPES, `${destinationAccountType}.name`, destinationBalanceType))}
                            </span>
                        </div>
                    </td>
                    {canTransfer && <>
                        {status === TRANSFER_STATUS.UNSUPPORTED ? <td colSpan={2}>
                            <div className='portfolio-guard--transfer--not-supported-message'>{'Transfer Unsupported'}</div>
                        </td> : <>
                            <td>
                                {[TRANSFER_STATUS.READY, TRANSFER_STATUS.FAILED].includes(status)
                                    ? <button className='portfolio-guard--transfer--transfer-button'
                                        onClick={() => { onClickTransfer() }}>
                                        {'Transfer'}
                                    </button>
                                    :  <div className={`portfolio-guard--transfer--status ${status}`}>{status}</div>}
                            </td>
                            <td>
                                {(status === TRANSFER_STATUS.FAILED || !_.isEmpty(message)) &&
                                <div className={`portfolio-guard--transfer--message` + (status === TRANSFER_STATUS.FAILED ? ' warning' : '')}>
                                    {status === TRANSFER_STATUS.FAILED && <>
                                        {`Failed${!_.isEmpty(message) ? ': ' : ''}`}
                                    </>}
                                    {message}
                                </div>}
                            </td>
                        </>}
                    </>}
                </tr>
            )
        }

        return (
            <div className='portfolio-guard--transfers'>
                <button className='portfolio-guard--transfers--generate-margin-allocation-button'
                    disabled={!areParamsValid}
                    onClick={() => { handleClickGenerateAutoMarginAllocation() }}>
                    <FaWandMagicSparkles />
                    {'Generate Margin Allocation'}
                </button>
                {Number(key) > 0 &&
                <div className='portfolio-guard--transfers--main'>
                    <div className='portfolio-guard--transfers--head'>
                        <div className='portfolio-guard--transfers--title'>{title}</div>
                        <div className='portfolio-guard--transfers--stats'>
                            <div>
                                <label>{'Required Margin'}</label>
                                <span>{`$${_totalRequiredMargin.toFormat(0, 1)}`}</span>
                            </div>
                            <div>
                                <label>{'Collectible Margin'}</label>
                                <span>{`$${_totalCollectibleMargin.toFormat(0, 1)}`}</span>
                            </div>
                            {_totalRequiredMargin.gt(0) && <div className={_fulFilledRate.lt(1) ? 'negative' : 'positive'}>
                                <label>{'Coverage'}</label>
                                <span>{`${_fulFilledRate.times(100).toFixed(0, 1)}%`}</span>
                            </div>}
                        </div>
                    </div>
                    <div className='portfolio-guard--transfers--body' key={key}>
                        {!_.isEmpty(intraMainAccountTransfers) &&
                        <section className='portfolio-guard--transfers--section'>
                            <div className='portfolio-guard--transfers--section--title'>
                                <FaCaretRight />
                                {`Intra Main Account Transfers (${_.size(intraMainAccountTransfers)})`}
                            </div>
                            <div className='portfolio-guard--transfers--section--main intra-main-account-transfers'>
                                <table>
                                    {TransferTableHeader({
                                        canTransfer: true,
                                        isTransfering: isTransferringIntraMainAccountTransfers,
                                        transfers: intraMainAccountTransfers,
                                        onClickTransfer: () => { handleClickBulkTransfer(BULK_TRANSFER_TYPES.INTRA_MAIN_ACCOUNT_TRANSFERS) },
                                        onClickDiscard: () => {
                                            setIsTransferringIntraMainAccountTransfers(false)
                                            setMarginAllocation(prevAllocation => {
                                                const _newTransfers = _.cloneDeep(prevAllocation.intraMainAccountTransfers)
                                                _.forEach(_newTransfers, _transfer => {
                                                    if (_transfer.status === TRANSFER_STATUS.PENDING) {
                                                        _transfer.status = TRANSFER_STATUS.READY
                                                    }
                                                })
                                                return dotProp.set(prevAllocation, 'intraMainAccountTransfers', _newTransfers)
                                            })
                                        }
                                    })}
                                    <tbody>
                                        {_.map(intraMainAccountTransfers, (_transfer, _index) => {
                                            return (
                                                <Fragment key={_index}>
                                                    {Transfer({
                                                        transfer: _transfer,
                                                        canTransfer: true,
                                                        onClickTransfer: () => { handleTransfer(key, `intraMainAccountTransfers.${_index}`) }
                                                    })}
                                                </Fragment>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </section>}
                        {!_.isEmpty(interMainAccountTransfer.crossMainAccountTransfers) &&
                        <section className='portfolio-guard--transfers--section'>
                            <div className='portfolio-guard--transfers--section--title'>
                                <FaCaretRight />
                                {'Inter Main Account Transfers'}
                            </div>
                            <div className='portfolio-guard--transfers--section--main inter-main-account-transfers'>
                                {!_.isEmpty(interMainAccountTransfer.collections) &&
                                <section>
                                    <div>{`Collections (${_.size(interMainAccountTransfer.collections)})`}</div>
                                    <table>
                                        {TransferTableHeader({
                                            canTransfer: true,
                                            isTransfering: isTransferringInterMainAccountCollections,
                                            transfers: interMainAccountTransfer.collections,
                                            onClickTransfer: () => { handleClickBulkTransfer(BULK_TRANSFER_TYPES.INTER_MAIN_ACCOUNT_COLLECTIONS) },
                                            onClickDiscard: () => {
                                                setIsTransferringInterMainAccountCollections(false)
                                                setMarginAllocation(prevAllocation => {
                                                    const _newTransfers = _.cloneDeep(prevAllocation.interMainAccountTransfer.collections)
                                                    _.forEach(_newTransfers, _transfer => {
                                                        if (_transfer.status === TRANSFER_STATUS.PENDING) {
                                                            _transfer.status = TRANSFER_STATUS.READY
                                                        }
                                                    })
                                                    return dotProp.set(prevAllocation, 'interMainAccountTransfer.collections', _newTransfers)
                                                })
                                            } 
                                        })}
                                        <tbody>
                                            {_.map(interMainAccountTransfer.collections, (_transfer, _index) => {
                                                return (
                                                    <Fragment key={_index}>
                                                        {Transfer({
                                                            transfer: _transfer,
                                                            canTransfer: true,
                                                            onClickTransfer: () => { handleTransfer(key, `interMainAccountTransfer.collections.${_index}`) }
                                                        })}
                                                    </Fragment>
                                                )
                                            })}
                                        </tbody>
                                    </table>
                                </section>}
                                <section>
                                    <div>{`X-Main-Account Transfers (${_.size(interMainAccountTransfer.crossMainAccountTransfers)})`}</div>
                                    <table>
                                        {TransferTableHeader({ canTransfer: false })}
                                        <tbody>
                                            {_.map(interMainAccountTransfer.crossMainAccountTransfers, (_transfer, _index) => {
                                                return (
                                                    <Fragment key={_index}>
                                                        {Transfer({
                                                            transfer: _transfer,
                                                            canTransfer: false
                                                        })}
                                                    </Fragment>
                                                )
                                            })}
                                        </tbody>
                                    </table>
                                </section>
                                {!_.isEmpty(interMainAccountTransfer.distributions) &&
                                <section>
                                    <div>{`Distributions (${_.size(interMainAccountTransfer.distributions)})`}</div>
                                    <table>
                                        {TransferTableHeader({
                                            canTransfer: true,
                                            isTransfering: isTransferringInterMainAccountDistributions,
                                            transfers: interMainAccountTransfer.distributions,
                                            onClickTransfer: () => { handleClickBulkTransfer(BULK_TRANSFER_TYPES.INTER_MAIN_ACCOUNT_DISTRIBUTIONS) },
                                            onClickDiscard: () => {
                                                setIsTransferringInterMainAccountDistributions(false)
                                                setMarginAllocation(prevAllocation => {
                                                    const _newTransfers = _.cloneDeep(prevAllocation.interMainAccountTransfer.distributions)
                                                    _.forEach(_newTransfers, _transfer => {
                                                        if (_transfer.status === TRANSFER_STATUS.PENDING) {
                                                            _transfer.status = TRANSFER_STATUS.READY
                                                        }
                                                    })
                                                    return dotProp.set(prevAllocation, 'interMainAccountTransfer.distributions', _newTransfers)
                                                })
                                            } 
                                        })}
                                        <tbody>
                                            {_.map(interMainAccountTransfer.distributions, (_transfer, _index) => {
                                                return (
                                                    <Fragment key={_index}>
                                                        {Transfer({
                                                            transfer: _transfer,
                                                            canTransfer: true,
                                                            onClickTransfer: () => { handleTransfer(key, `interMainAccountTransfer.distributions.${_index}`) }
                                                        })}
                                                    </Fragment>
                                                )
                                            })}
                                        </tbody>
                                    </table>
                                </section>}
                            </div>
                        </section>}
                    </div>
                </div>}
            </div>
        )
    }

    function Chart () {
        return (
            <div className='portfolio-guard--chart'>
                <div className='portfolio-guard--chart--head'
                    onClick={() => { setShouldShowChart(!shouldShowChart) }}>
                    <label>{'Margin Overview'}</label>
                    {shouldShowChart ? <FaCaretDown /> : <FaCaretLeft />}
                </div>
                {shouldShowChart && <div className='portfolio-guard--chart--main'>
                    {areParamsValid && 
                    <MarginOverviewChart
                        collectionOverviewPerMainAccount={collectionOverviewPerMainAccount}
                        requirementOverviewPerMainAccount={requirementOverviewPerMainAccount}
                        transferableCoins={transferableCoins}
                        onClickCollectMargin={({ mainAccountName, coin }) => { handleClickCollectMainAccountMargin(mainAccountName, coin) }}
                        onClickFulfillRequiredMargin={({ mainAccountName }) => { handleClickFulfilRequiredMargin(mainAccountName) }} />}
                </div>}
            </div>
        )
    }

    return (
        <div className='portfolio-guard'>
            <div className='portfolio-guard--head'>
                {Portfolios()}
                <button className='portfolio-guard--fetch-latest-x-margin-button'
                    disabled={isFetchingLiquidationRatio}
                    onClick={() => { handleClickFetchXMarginButon() }}>
                    {isFetchingLiquidationRatio ? <>
                        <ReactLoading className='portfolio-guard--fetch-latest-x-margin-button--loading'
                            type={'spin'}
                            color='#0f171f' />
                        {'Fetching'}
                    </> : 'Fetch Latest X-Margin Data'}
                </button>
            </div>
            <div className='portfolio-guard--main'>
                <div className='portfolio-guard--collections-and-requirements'>
                    {MarginRequirements()}
                    {MarginCollections()}
                </div>
                <div className='portfolio-guard--chart-and-transfers'>
                    {Chart()}
                    {Transfers()}
                </div>
            </div>
        </div>
    )
}

export default memo(PortfolioGuard)