import _ from 'lodash'
import { secureFetch } from '../../util/util'
import { ELF_API_BASE_URL, DEFAULT_SERVER } from '../../configs/config'
import { batch } from 'react-redux'
import { dumpBufferedBalancesAndPositions } from '../webSocket/webSocketAction'

export const UPDATE_ACCOUNT_ITEMS = 'UPDATE_ACCOUNT_ITEMS'
export const UPDATE_ACCOUNT_ASSET = 'UPDATE_ACCOUNT_ASSET'
export const UPDATE_ACCOUNT_SPOT_BALANCE = 'UPDATE_ACCOUNT_SPOT_BALANCE'
export const UPDATE_ACCOUNT_MARGIN_BALANCE = 'UPDATE_ACCOUNT_MARGIN_BALANCE'
export const UPDATE_ACCOUNT_CROSS_MARGIN_BALANCE = 'UPDATE_ACCOUNT_CROSS_MARGIN_BALANCE'
export const UPDATE_ACCOUNT_FUTURE_BALANCE = 'UPDATE_ACCOUNT_FUTURE_BALANCE'
export const UPDATE_ACCOUNT_SWAP_BALANCE = 'UPDATE_ACCOUNT_SWAP_BALANCE'
export const UPDATE_ACCOUNT_WALLET_BALANCE = 'UPDATE_ACCOUNT_WALLET_BALANCE'
export const UPDATE_ACCOUNT_BALANCES = 'UPDATE_ACCOUNT_BALANCES'
export const UPDATE_ACCOUNT_CROSS_BALANCE = 'UPDATE_ACCOUNT_CROSS_BALANCE'
export const UPDATE_ACCOUNT_OPTION_BALANCE = 'UPDATE_ACCOUNT_OPTION_BALANCE'
export const UPDATE_ACCOUNT_CONTRACT_BALANCE = 'UPDATE_ACCOUNT_CONTRACT_BALANCE'
export const UPDATE_ACCOUNT_FUND_BALANCE = 'UPDATE_ACCOUNT_FUND_BALANCE'
export const UPDATE_ACCOUNT_FUNDING_BALANCE = 'UPDATE_ACCOUNT_FUNDING_BALANCE'
export const UPDATE_BNBFUTA_PM2DOT0_UNIFIED_BALANCE = 'UPDATE_BNBFUTA_PM2DOT0_UNIFIED_BALANCE'
export const UPDATE_ACCOUNT_AVAILABLE_BALANCE = 'UPDATE_ACCOUNT_AVAILABLE_BALANCE'
export const UPDATE_ACCOUNT_RISK_INFO = 'UPDATE_ACCOUNT_RISK_INFO'
export const UPDATE_PORTFOLIO_MARGIN_ACCOUNT_INFO = 'UPDATE_PORTFOLIO_MARGIN_ACCOUNT_INFO'
export const UPDATE_DERIBIT_PORTFOLIO_MARGIN_ACCOUNT_INFO = 'UPDATE_DERIBIT_PORTFOLIO_MARGIN_ACCOUNT_INFO'
export const UPDATE_DERIBIT_PORTFOLIO_MARGIN_ACCOUNT_IM_INFO = 'UPDATE_DERIBIT_PORTFOLIO_MARGIN_ACCOUNT_IM_INFO'
export const UPDATE_PORTFOLIO_MARGIN_VIRTUAL_ACCOUNT_INFO = 'UPDATE_PORTFOLIO_MARGIN_VIRTUAL_ACCOUNT_INFO'
export const UPDATE_PORTFOLIO_EFF_RATIO_VIRTUAL_ACCOUNT_INFO = 'UPDATE_PORTFOLIO_EFF_RATIO_VIRTUAL_ACCOUNT_INFO'
export const UPDATE_ACCOUNT_SPOT_LOAN_BALANCE = 'UPDATE_ACCOUNT_SPOT_LOAN_BALANCE'

export function fetchAccountBalances () {
    return async (dispatch) => {
        const result = {
            spotBalance: {},
            marginBalance: {},
            futureBalance: {},
            swapBalance: {},
            walletBalance: {},
            asset: {}
        }
        const actions = [{
            key: 'spotBalance',
            functionName: fetchAccountSpotBalance,
            params: [true]
        }, {
            key: 'marginBalance',
            functionName: fetchAccountMarginBalance,
            params: [true]
        }, {
            key: 'futureBalance',
            functionName: fetchAccountFutureBalance,
            params: [true]
        }, {
            key: 'swapBalance',
            functionName: fetchAccountSwapBalance,
            params: [true]
        }, {
            key: 'walletBalance',
            functionName: fetchAccountWalletBalance,
            params: [true]
        }, {
            key: 'asset',
            functionName: fetchAccountAsset,
            params: [true]
        }]

        await Promise.all(_.map(actions, async (action) => {
            const { key, functionName, params } = action 
            try {
                result[key] = await dispatch(functionName(...params))
            } catch (error) {
                console.error('fetchAccountBalances: ', error)
            }
        }))

        batch(() => {
            if (!_.isEmpty(result.spotBalance)) {
                dispatch({
                    type: UPDATE_ACCOUNT_SPOT_BALANCE,
                    spotBalance: result.spotBalance
                })
            }
            if (!_.isEmpty(result.marginBalance)) {
                dispatch({
                    type: UPDATE_ACCOUNT_MARGIN_BALANCE,
                    marginBalance: result.marginBalance
                })
            }
            if (!_.isEmpty(result.futureBalance)) {
                dispatch({
                    type: UPDATE_ACCOUNT_FUTURE_BALANCE,
                    futureBalance: result.futureBalance
                })
            }
            if (!_.isEmpty(result.swapBalance)) {
                dispatch({
                    type: UPDATE_ACCOUNT_SWAP_BALANCE,
                    swapBalance: result.swapBalance
                })
            }
            if (!_.isEmpty(result.walletBalance)) {
                dispatch({
                    type: UPDATE_ACCOUNT_WALLET_BALANCE,
                    walletBalance: result.walletBalance
                })
            }
            if (!_.isEmpty(result.asset)) {
                dispatch({
                    type: UPDATE_ACCOUNT_ASSET,
                    asset: result.asset
                })
            }
        })
    }
}

export function fetchAccounts () {
    return (dispatch) => {
        dispatch(secureFetch(`${DEFAULT_SERVER.apiBaseUrl}/accounts`, {
            method: 'GET'
        })).then((response) => {
            if (response.status === 200) {
                response.json().then((body) => {
                    const accountItems = _.keyBy(body.info, 'account_name')
                    dispatch({
                        type: UPDATE_ACCOUNT_ITEMS,
                        accounts: accountItems
                    })
                })
            }
        })
    }
}

export function fetchAccountSpotLoanBalance (shouldSkipStoreUpdate=false) {
    return (dispatch) => new Promise((resolve, reject) => {
        dispatch(secureFetch(`${ELF_API_BASE_URL}/spotLoanAccountBalances`))
        .then(response => response.json())
        .then(body => {
            if (!_.isArray(body)) {
                throw new Error(`Unexpected Return`)
            } else {
                const spotLoanBalance = _.keyBy(body, item => `${item.acct_name}--${item.coin}`)
                if (!shouldSkipStoreUpdate) {
                    dispatch({
                        type: UPDATE_ACCOUNT_SPOT_LOAN_BALANCE,
                        spotLoanBalance
                    })
                }
                resolve(spotLoanBalance)
            }
        })
        .catch(error => {
            console.error('fetchAccountSpotLoanBalance Error: ', error)
            reject(error)
        })
    })
}

export function fetchAccountSpotBalance (shouldSkipStoreUpdate=false) {
    return (dispatch) => new Promise((resolve, reject) => {
        dispatch(secureFetch(`${DEFAULT_SERVER.apiBaseUrl}/spot-account-balance`))
        .then(response => response.json())
        .then(body => {
            if (body && _.isArray(body.info)) {
                let spotBalance = {}
                if (!shouldSkipStoreUpdate) {
                    spotBalance = _.keyBy(body.info, spotAccountBalance => `${spotAccountBalance.acct_name}--${spotAccountBalance.coin}`)
                    dispatch({
                        type: UPDATE_ACCOUNT_SPOT_BALANCE,
                        spotBalance
                    })
                }
                resolve(spotBalance)
            } else {
                throw new Error('Unexpected Return')
            }
        })
        .catch(error => {
            console.error('fetchAccountSpotBalance Error: ', error)
            reject(error)
        })
    })
}

export function fetchAccountMarginBalance (shouldSkipStoreUpdate=false) {
    return (dispatch) => new Promise((resolve, reject) => {
        dispatch(secureFetch(`${DEFAULT_SERVER.apiBaseUrl}/margin-account-balance`))
        .then(response => response.json()) 
        .then(body => {
            if (body && _.isArray(body.info)) {
                let marginBalance = _.keyBy(body.info, marginAccountBalance => `${marginAccountBalance.acct_name}--${marginAccountBalance.pair}`)
                if (!shouldSkipStoreUpdate) {
                    dispatch({
                        type: UPDATE_ACCOUNT_MARGIN_BALANCE,
                        marginBalance
                    })
                }
                resolve(marginBalance)
            } else {
                throw new Error('Unexpected Return')
            }
        })
        .catch(error => {
            console.error('fetchAccountMarginBalance Error: ', error)
            reject(error)
        })
    })
}

export function fetchAccountFutureBalance (shouldSkipStoreUpdate=false) {
    return (dispatch) => new Promise((resolve, reject) => {
        dispatch(secureFetch(`${DEFAULT_SERVER.apiBaseUrl}/future-account-balance`))
        .then(response => response.json())
        .then(body => {
            if (body && _.isArray(body.info)) {
                let futureBalance = _.keyBy(body.info, futureAccountBalance => `${futureAccountBalance.acct_name}--${futureAccountBalance.coin}`)
                if (!shouldSkipStoreUpdate) {
                    dispatch({
                        type: UPDATE_ACCOUNT_FUTURE_BALANCE,
                        futureBalance
                    })
                }
                resolve(futureBalance)
            } else {
                throw new Error('Unexpected Return')
            }
        })
        .catch(error => {
            console.error('fetchAccountFutureBalance Error: ', error)
            reject(error)
        })
    })
}

export function fetchAccountSwapBalance (shouldSkipStoreUpdate=false) {
    return (dispatch) => new Promise((resolve, reject) => {
        dispatch(secureFetch(`${DEFAULT_SERVER.apiBaseUrl}/swap-account-balance`))
        .then(response => response.json())
        .then(body => {
            if (body && _.isArray(body.info)) {
                let swapBalance = _.keyBy(body.info, swapAccountBalance => `${swapAccountBalance.acct_name}--${swapAccountBalance.coin}`)
                if (!shouldSkipStoreUpdate) {
                    dispatch({
                        type: UPDATE_ACCOUNT_SWAP_BALANCE,
                        swapBalance
                    })
                }
                resolve(swapBalance)
            } else {
                throw new Error('Unexpected Return')
            }
        })
        .catch(error => {
            console.error('fetchAccountSwapBalance Error: ', error)
            reject(error)
        })
    })
}

export function fetchAccountWalletBalance (shouldSkipStoreUpdate=false) {
    return (dispatch) => new Promise((resolve, reject) => {
        dispatch(secureFetch(`${ELF_API_BASE_URL}/wallet-account-balance`))
        .then(response => response.json())
        .then(body => {
            if (body && _.isArray(body)) {
                let walletBalance = _.keyBy(body, item => `${item.acct_name}--${item.coin}`)
                if (!shouldSkipStoreUpdate) {
                    dispatch({
                        type: UPDATE_ACCOUNT_WALLET_BALANCE,
                        walletBalance
                    })
                }
                resolve(walletBalance)
            } else {
                throw new Error('Unexpected Return')
            }
        })
        .catch(error => {
            console.error('fetchAccountWalletBalance Error: ', error)
            reject(error)
        })
    })
}

export function fetchAccountAsset (shouldSkipStoreUpdate=false) {
    return (dispatch) => new Promise((resolve, reject) => {
        dispatch(secureFetch(`${ELF_API_BASE_URL}/balance`))
        .then(response => response.json())
        .then(body => {
            if (body && _.isArray(body)) {
                let asset = _.keyBy(body, 'acct_name')
                if (!shouldSkipStoreUpdate) {
                    dispatch({
                        type: UPDATE_ACCOUNT_ASSET,
                        asset
                    })
                }
                resolve(asset)
            }
        })
        .catch(error => {
            console.error('fetchAccountAsset Error: ', error)
            reject(error)
        })
    })
}

export function fetchAccountTransferableDetails () {
    return (dispatch) => new Promise((resolve, reject) => {
        dispatch(secureFetch(`${ELF_API_BASE_URL}/accountTransferableDetails`))
        .then(response => response.json())
        .then(body => {
            if (!_.isNil(body) && _.isArray(body)) {
                resolve(body)
            }
        })
        .catch(error => {
            console.error('fetchAccountTransferableDetails Error: ', error)
            reject(error)
        })
    })
}

export function fetchAvailableBalance () {
    return (dispatch) => new Promise((resolve, reject) => {
        dispatch(secureFetch(`${ELF_API_BASE_URL}/availableBalance`))
        .then(response => response.json())
        .then(body => {
            if (_.isArray(body)) {
                dispatch({
                    type: UPDATE_ACCOUNT_AVAILABLE_BALANCE,
                    availableBalance: body
                })
                resolve(body)
            } else {
                throw new Error('unexpected return')
            }
        })
        .catch(error => {
            console.error('fetchAvailableBalance Error: ', error)
            reject(error)
        })
    })   
}

export function accountTransferFund ({ account_name, currency, from, instrument_id, to_account_name, to, to_instrument_id, amount }) {
    return (dispatch) => {
        return dispatch(secureFetch(`${DEFAULT_SERVER.apiBaseUrl}/transfer`, {
            method: 'POST',
            body: JSON.stringify({
                account_name,
                currency,
                from,
                instrument_id,
                to_account_name,
                to,
                to_instrument_id,
                amount
            })
        }))
    }
}

export function accountDepositFund ({ account_name, to_account_name, currency, amount }) {
    return (dispatch) => new Promise((resolve, reject) => {
        dispatch(secureFetch(`${DEFAULT_SERVER.apiBaseUrl}/deposit`, {
            method: 'POST',
            body: JSON.stringify({
                account_name,
                to_account_name,
                currency,
                amount
            })
        }))
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
}

export function updateAccountBalances ({ spotAccountBalances=[], marginAccountBalances=[], futureAccountBalances=[], swapAccountBalances=[], shouldMerge=false }) {
    return (dispatch) => {
        dispatch({
            type: UPDATE_ACCOUNT_BALANCES,
            spotBalance: _.keyBy(spotAccountBalances, spotAccountBalance => `${spotAccountBalance.acct_name}--${spotAccountBalance.coin}`),
            marginBalance: _.keyBy(marginAccountBalances, marginAccountBalance => `${marginAccountBalance.acct_name}--${marginAccountBalance.pair}`),
            futureBalance: _.keyBy(futureAccountBalances, futureAccountBalance => `${futureAccountBalance.acct_name}--${futureAccountBalance.coin}`),
            swapBalance: _.keyBy(swapAccountBalances, swapAccountBalance => `${swapAccountBalance.acct_name}--${swapAccountBalance.coin}`),
            shouldMerge
        })
    }
}

export function updateAccountBalanceMonitorVariables (variables) {
    return (dispatch) => dispatch(secureFetch(`${ELF_API_BASE_URL}/account-balance-monitor/variables`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(variables)
    }))
}

export function fetchAccountBalancerState () {
    return (dispatch) => dispatch(secureFetch(`${ELF_API_BASE_URL}/account-balancer-state`))
}

export function updateAccountBalancerVariables (variables) {
    return (dispatch) => dispatch(secureFetch(`${ELF_API_BASE_URL}/account-balancer/variables`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(variables)
    }))
}

export function fetchTransfersToBalanceAccountGroup (groupId) {
    return (dispatch) => dispatch(secureFetch(`${ELF_API_BASE_URL}/account-balancer/transfers-to-balance-group?groupId=${groupId}`))
}

export function syncAccountBalances (accounts=[]) {
    return (dispatch) => new Promise((resolve, reject) => {
        dispatch(secureFetch(`${DEFAULT_SERVER.apiBaseUrl}/balances/sync`, {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(accounts)
        }))
        .then(response => response.json())
        .then(body => {
            dispatch(dumpBufferedBalancesAndPositions())
            if (!_.isEmpty(body)) {
                const newSpotBalance={}, newCrossAccountBalance={}, newCrossMarginAccountBalance={}, newFutureAccountBalance={}, newSwapAccountBalance={}, newWalletAccountBalance={}, newBalance={}
                _.forEach(body, (accountBalances) => {
                    const { spot_account_balance, cross_account_balance, cross_margin_account_balance, future_account_balance, swap_account_balance, wallet_account_balance, balance } = accountBalances
                    if (!_.isEmpty(spot_account_balance?.info)) {
                        _.assign(newSpotBalance, _.keyBy(spot_account_balance.info, spotAccountBalance => `${spotAccountBalance.acct_name}--${spotAccountBalance.coin}`))
                    }
                    if (!_.isEmpty(cross_account_balance?.info)) {
                        _.assign(newCrossAccountBalance, _.keyBy(cross_account_balance.info, crossAccountBalance => `${crossAccountBalance.acct_name}--${crossAccountBalance.coin}`))
                    }
                    if (!_.isEmpty(cross_margin_account_balance?.info)) {
                        _.assign(newCrossMarginAccountBalance,  _.keyBy(cross_margin_account_balance.info, crossMarginAccountBalance => `${crossMarginAccountBalance.acct_name}--${crossMarginAccountBalance.coin}`))
                    }
                    if (!_.isEmpty(future_account_balance?.info)) {
                        _.assign(newFutureAccountBalance, _.keyBy(future_account_balance.info, futureAccountBalance => `${futureAccountBalance.acct_name}--${futureAccountBalance.coin}`))
                    }
                    if (!_.isEmpty(swap_account_balance?.info)) {
                        _.assign(newSwapAccountBalance, _.keyBy(swap_account_balance.info, swapAccountBalance => `${swapAccountBalance.acct_name}--${swapAccountBalance.coin}`))
                    }
                    if (!_.isEmpty(wallet_account_balance?.info)) {
                        _.assign(newWalletAccountBalance, _.keyBy(wallet_account_balance.info, walletAccountBalance => `${walletAccountBalance.acct_name}--${walletAccountBalance.coin}`))
                    }
                    if (!_.isEmpty(balance?.info)) {
                        _.assign(newBalance, _.keyBy(balance.info, 'acct_name'))
                    }
                })
                
                batch(() => {
                    if (!_.isEmpty(newSpotBalance)) {
                        dispatch({
                            type: UPDATE_ACCOUNT_SPOT_BALANCE,
                            spotBalance: newSpotBalance,
                            shouldMerge: true
                        })
                    }
                    if (!_.isEmpty(newCrossAccountBalance)) {
                        dispatch({
                            type: UPDATE_ACCOUNT_CROSS_BALANCE,
                            crossBalance: newCrossAccountBalance,
                            shouldMerge: true
                        })
                    }
                    if (!_.isEmpty(newCrossMarginAccountBalance)) {
                        dispatch({
                            type: UPDATE_ACCOUNT_CROSS_MARGIN_BALANCE,
                            crossMarginBalance: newCrossMarginAccountBalance,
                            shouldMerge: true
                        })
                    }
                    if (!_.isEmpty(newFutureAccountBalance)) {
                        dispatch({
                            type: UPDATE_ACCOUNT_FUTURE_BALANCE,
                            futureBalance: newFutureAccountBalance,
                            shouldMerge: true
                        })
                    }
                    if (!_.isEmpty(newSwapAccountBalance)) {
                        dispatch({
                            type: UPDATE_ACCOUNT_SWAP_BALANCE,
                            swapBalance: newSwapAccountBalance,
                            shouldMerge: true
                        })
                    }
                    if (!_.isEmpty(newWalletAccountBalance)) {
                        dispatch({
                            type: UPDATE_ACCOUNT_WALLET_BALANCE,
                            walletBalance: newWalletAccountBalance,
                            shouldMerge: true
                        })
                    }
                    if (!_.isEmpty(newBalance)) {
                        dispatch({
                            type: UPDATE_ACCOUNT_ASSET,
                            asset: newBalance,
                            shouldMerge: true
                        })
                    }
                })
                resolve(body)
            } else {
                throw new Error('Unexpected return')
            }

        })
        .catch(error => {
            console.error(`syncAccountBalances error: `, error)
            reject(error)
        })
        .finally(() => {
            dispatch(fetchAccountTransferableDetails())
            dispatch(fetchAvailableBalance())
        })
    })
}

export function accountBorrowFund ({ account_name, pair, currency, amount }) {
    return (dispatch) => new Promise((resolve, reject) => {
        dispatch(secureFetch(`${DEFAULT_SERVER.apiBaseUrl}/borrow`, {
            method: 'POST',
            body: JSON.stringify({
                account_name,
                pair,
                currency,
                amount
            })
        }))
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
}

export function accountRepayFund ({ account_name, pair, currency, amount }) {
    return (dispatch) => new Promise((resolve, reject) => {
        dispatch(secureFetch(`${DEFAULT_SERVER.apiBaseUrl}/repay`, {
            method: 'POST',
            body: JSON.stringify({
                account_name,
                pair,
                currency,
                amount
            })
        }))
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
}

export function fetchMarginLendingMonitorState () {
    return (dispatch) => dispatch(secureFetch(`${ELF_API_BASE_URL}/margin-lending-monitor/state`))
}

export function updateMarginLendingMonitorVariables (variables) {
    return (dispatch) => dispatch(secureFetch(`${ELF_API_BASE_URL}/margin-lending-monitor/variables`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(variables)
    }))
}

export function fetchAccountRiskInfo () {
    return (dispatch) => new Promise((resolve, reject) => {
        dispatch(secureFetch(`${ELF_API_BASE_URL}/riskInfo`))
        .then(response => response.json())
        .then(body => {
            if (!_.isArray(body)) {
                throw new Error('Unexpected return')
            } else {
                dispatch({
                    type: UPDATE_ACCOUNT_RISK_INFO,
                    accountRiskInfo: body
                })
                resolve(body)
            }
        })
        .catch(error => {
            console.error(`fetchAccountRiskInfo error: `, error)
            reject(error)
        })
    })
}