import _ from 'lodash'
import { store } from '../store'
import { EXCHANGES_CAN_SUPPORT_SMART_POSITIONS } from '../configs/tradingConfig'

import { getSymbolAttributeByName, INSTRUMENT_TYPES, SYMBOL_SWITCH_TYPES } from './symbolUtil'
import { getNotional } from './tradingUtil'

const shouldProfileSymbolTradeInOkexMarginExchange = ({ profileItem, symbolName, side='BUY' }) => {
    const { exchangeName: symbolExchangeName, instrumentType } = getSymbolAttributeByName(symbolName)
    return _.has(profileItem, 'legs')
        && _.some(profileItem.legs, (legItem, legKey) => {
            return _.isArray(legItem.symbols)
                && _.some(legItem.symbols, profileSymbolItem => {
                    let marginTradeEnabled = null
                    if (_.has(profileSymbolItem, 'params')) {
                        marginTradeEnabled = legKey === '1' ? profileSymbolItem.params.QUOTE_MARGIN_TRADE_ENABLED
                            : legKey === '2' ? profileSymbolItem.params.HEDGE_MARGIN_TRADE_ENABLED
                            : null
                    }
                    return profileSymbolItem.name === symbolName
                        && symbolExchangeName === 'OKEX'
                        && instrumentType === INSTRUMENT_TYPES.SPOT
                        && _.isArray(marginTradeEnabled)
                        && _.size(marginTradeEnabled) === 2
                        && marginTradeEnabled[side === 'BUY' ? 0 : 1] === true
                        && _.has(profileItem, 'accounts.OKEXSPOTMARGIN')
                        && profileItem.accounts.OKEXSPOTMARGIN !== 'INVALID'
                })
        })
}

export const getProfileExchangeNameBySymbol = (profileItem, symbolName) => {
    const { items: symbolItems } = store.getState().symbol
    const { exchangeName: symbolExchangeName, instrumentType, quote } = getSymbolAttributeByName(symbolName)
    const tradingInNotional = _.get(symbolItems, `${symbolName}.trading_in_notional`, '0')
    const profileExchangeName = { 
        BUY: symbolExchangeName, 
        SELL: symbolExchangeName 
    }  
    if (symbolExchangeName === 'OKEX') { // fucking dirty here
        if (instrumentType === INSTRUMENT_TYPES.SPOT) {
            profileExchangeName.BUY = shouldProfileSymbolTradeInOkexMarginExchange({ profileItem, symbolName, side: 'BUY' }) ? 'OKEXSPOTMARGIN' : 'OKEXSPOT'
            profileExchangeName.SELL = shouldProfileSymbolTradeInOkexMarginExchange({ profileItem, symbolName, side: 'SELL' }) ? 'OKEXSPOTMARGIN' : 'OKEXSPOT'
        } else if ([INSTRUMENT_TYPES.FUTURE, INSTRUMENT_TYPES.OPTION].includes(instrumentType)) {
            profileExchangeName.BUY = 'OKEXCONTRACT'
            profileExchangeName.SELL = profileExchangeName.BUY 
        } else if (instrumentType === INSTRUMENT_TYPES.SWAP) {
            profileExchangeName.BUY = quote === 'USDT' ? 'OKEXUSDTSWAP' : 'OKEXUSDSWAP'
            profileExchangeName.SELL = profileExchangeName.BUY
        }
    } else if (symbolExchangeName === 'FTX' && !_.has(profileItem, 'accounts.FTX')) {
        if (instrumentType === INSTRUMENT_TYPES.SPOT && _.has(profileItem, 'accounts.FTXSPOT')) {
            profileExchangeName.BUY  = 'FTXSPOT'
            profileExchangeName.SELL = profileExchangeName.BUY
        } else if ([INSTRUMENT_TYPES.FUTURE, INSTRUMENT_TYPES.SWAP].includes(instrumentType) && _.has(profileItem, 'accounts.FTXFUT')){
            profileExchangeName.BUY  = 'FTXFUT'
            profileExchangeName.SELL = profileExchangeName.BUY
        } else if (instrumentType === INSTRUMENT_TYPES.OPTION && _.has(profileItem, 'accounts.FTXOPT')) {
            profileExchangeName.BUY  = 'FTXOPT'
            profileExchangeName.SELL = profileExchangeName.BUY
        }
    } else if (symbolExchangeName === 'HUOBIFUT' && instrumentType === INSTRUMENT_TYPES.SWAP && _.has(profileItem, `accounts.HUOBIFUTSWAP`)) {
        profileExchangeName.BUY  = 'HUOBIFUTSWAP'
        profileExchangeName.SELL = profileExchangeName.BUY
    } else if (symbolExchangeName === 'BNBFUTA') {
        if (instrumentType === INSTRUMENT_TYPES.OPTION && _.has(profileItem, 'accounts.BNBFUTAOPT')) {
            profileExchangeName.BUY  = 'BNBFUTAOPT'
            profileExchangeName.SELL = profileExchangeName.BUY
        } else if (['BUSD', 'USDC'].includes(quote) && _.has(profileItem, 'accounts.BNBFUTABUSD')) {
            profileExchangeName.BUY  = 'BNBFUTABUSD'
            profileExchangeName.SELL = profileExchangeName.BUY
        } else if (['USDT', 'BUSD'].includes(quote) && _.has(profileItem, 'accounts.BNBFUTAUSDT')) {
            profileExchangeName.BUY  = 'BNBFUTAUSDT'
            profileExchangeName.SELL = profileExchangeName.BUY
        } else if (!['USDT', 'BUSD'].includes(quote) && _.has(profileItem, 'accounts.BNBFUTACOIN')) {
            profileExchangeName.BUY  = 'BNBFUTACOIN'
            profileExchangeName.SELL = profileExchangeName.BUY
        }
    } else if (symbolExchangeName === 'PHEMEX') {
        if (tradingInNotional === '1' && _.has(profileItem, 'accounts.PHEMEXBTCCONTRACT')) {
            profileExchangeName.BUY  = 'PHEMEXBTCCONTRACT'
            profileExchangeName.SELL = profileExchangeName.BUY
        } else if (symbolName !== 'btc_usd_PHEMEX_SWAP' && _.has(profileItem, 'accounts.PHEMEXUSDCONTRACT')) {
            profileExchangeName.BUY  = 'PHEMEXUSDCONTRACT'
            profileExchangeName.SELL = profileExchangeName.BUY
        }
    } else if (['EXCHPH', 'INTERNAL'].includes(symbolExchangeName)) {
        profileExchangeName.BUY = null
        profileExchangeName.SELL = null
    }
    return profileExchangeName
}

export const getProfileAccountNameBySymbol = (profileItem, symbolName) => {
    const profileExchangeName = getProfileExchangeNameBySymbol(profileItem, symbolName)
    const profileAccountName = {
        BUY: _.has(profileItem, `accounts.${profileExchangeName.BUY}`) ? profileItem.accounts[profileExchangeName.BUY] : null,
        SELL: _.has(profileItem, `accounts.${profileExchangeName.SELL}`) ? profileItem.accounts[profileExchangeName.SELL] : null
    }
    return profileAccountName
}

export const getProfileTradingAccountNamesBySymbol = (profileItem, symbolName) => {
    const symbolExchangeName = getProfileExchangeNameBySymbol(profileItem, symbolName)
    const symbolAccountName = getProfileAccountNameBySymbol(profileItem, symbolName)
    const result = {
        BUY: [],
        SELL: []
    }
    _.forEach(_.keys(result), side => {
        if (symbolAccountName[side] === 'smart_pos_acct') {
            const smartPositionAccounts = getProfileSmartPositionAccountsByExchangeName(profileItem, symbolExchangeName[side])
            result[side].push(...smartPositionAccounts[side])
            if (!_.isEmpty(smartPositionAccounts.BACKUP)) {
                result[side].push(smartPositionAccounts.BACKUP)
            }
        } else {
            result[side].push(symbolAccountName[side])
        }
    })
    return result
}

export const getProfileSymbolNames = (profileItem, refSymbolIncluded=false) => {
    const profileSymbolNames = _.has(profileItem, 'legs')
        ? _.reduce(profileItem.legs, (result, leg) => {
            if (_.isArray(leg.symbols)) {
                result.push(...leg.symbols.map(legSymbol => legSymbol.name))
                if (refSymbolIncluded) {
                    _.forEach(leg.symbols, legSymbolItem => {
                        if (_.isArray(legSymbolItem.refSymbols)) {
                            _.forEach(legSymbolItem.refSymbols, refSymbolItem => {
                                if (refSymbolItem.name && refSymbolItem.name !== 'INVALID') {
                                    result.push(refSymbolItem.name)
                                }
                            })
                        }
                    })
                }
            }
            return result
        }, [])
        : []
    return _.uniq(profileSymbolNames)
}

export const getProfileExchangeNames = (profileItem) => {
    const profileSymbolNames = getProfileSymbolNames(profileItem)
    const profileExchangeNames = []
    profileSymbolNames.forEach(symbolName => {
        const symbolExchangeName = getProfileExchangeNameBySymbol(profileItem, symbolName)
        profileExchangeNames.push(symbolExchangeName.BUY, symbolExchangeName.SELL)
    })
    return _.uniq(_.compact(profileExchangeNames))
}

export const getProfileSmartPositionAccountsByExchangeName = (profileItem, exchangeName) => {
    return {
        BUY: exchangeName && _.has(profileItem, `params.${exchangeName}_SMART_POS_BID_ACCOUNTS`) 
            ? (profileItem.params[`${exchangeName}_SMART_POS_BID_ACCOUNTS`].filter(accountName => ![undefined, null, 'INVALID'].includes(accountName)) || []) 
            : [],
        SELL: exchangeName && _.has(profileItem, `params.${exchangeName}_SMART_POS_ASK_ACCOUNTS`) 
            ? (profileItem.params[`${exchangeName}_SMART_POS_ASK_ACCOUNTS`].filter(accountName => ![undefined, null, 'INVALID'].includes(accountName)) || []) 
            : [],
        BACKUP: exchangeName && _.has(profileItem, `params.${exchangeName}_SMART_POS_BACKUP_ACCOUNT`) && ![undefined, null, 'INVALID'].includes(profileItem.params[`${exchangeName}_SMART_POS_BACKUP_ACCOUNT`])
            ? profileItem.params[`${exchangeName}_SMART_POS_BACKUP_ACCOUNT`]
            : null
    }
}

export const shouldProfileLegHaveLiquidationColumn = ({ profileItem, legKey, positionItems=[], marginAccountBalances={} }) => {
    return _.has(profileItem, `legs.${legKey}.symbols`) && _.some(profileItem.legs[legKey].symbols, symbol => {
        const { name: symbolName } = symbol
        const { instrumentType } = getSymbolAttributeByName(symbolName)
        const symbolNameTokens = symbol.name.split('_')
        const pairName = symbolNameTokens.length > 1 ? `${symbolNameTokens[0]}-${symbolNameTokens[1]}` : null
        const symbolTradingAccountNames = getProfileTradingAccountNamesBySymbol(profileItem, symbolName)
        const unionTradingAccountNames = _.uniq([...symbolTradingAccountNames.BUY, ...symbolTradingAccountNames.SELL])

        let marginTradeEnabled = [], crossMarginTradeEnabled = []
        if (_.has(symbol, 'params')) {
            marginTradeEnabled = legKey === '1' ? symbol.params.QUOTE_MARGIN_TRADE_ENABLED
                : legKey === '2' ? symbol.params.HEDGE_MARGIN_TRADE_ENABLED
                : []
            crossMarginTradeEnabled = legKey === '1' ? symbol.params.QUOTE_CROSS_MARGIN_TRADE_ENABLED
                : legKey === '2' ? symbol.params.HEDGE_CROSS_MARGIN_TRADE_ENABLED
                : []
        }

        return ([INSTRUMENT_TYPES.FUTURE, INSTRUMENT_TYPES.SWAP].includes(instrumentType) 
                && _.some(positionItems, positionItem => 
                    positionItem.product_name === symbolName 
                    && Number(positionItem.liquidation_price) !== 0 
                    && unionTradingAccountNames.includes(positionItem.account_name))
            ) 
            || (instrumentType === INSTRUMENT_TYPES.SPOT 
                && !_.isNil(pairName) 
                && _.size(marginTradeEnabled) === 2
                && _.size(crossMarginTradeEnabled) === 2
                && (_.some(['BUY', 'SELL'], side => 
                    (marginTradeEnabled[side === 'BUY' ? 0 : 1] || crossMarginTradeEnabled[side === 'BUY' ? 0 : 1])
                    && _.some(marginAccountBalances, marginAccountBalance => 
                        marginAccountBalance.pair === pairName
                        && symbolTradingAccountNames[side].includes(marginAccountBalance.acct_name)
                        && Number(marginAccountBalance.liquidation_price) !== 0)
                    )
                )
            )
    })
}

export const timerProfileItemHasSymbolConflict = (timerProfileItem, profileItem) => {
    const timerProfileSymbolNames = timerProfileItem.legs['1'].symbols.map(symbol => symbol.name)
    const profileSymbolNames = profileItem.legs['1'].symbols.map(symbol => symbol.name)
    return !_.isEqual(timerProfileSymbolNames, profileSymbolNames)
}

export const resolveTimerProfileItemSymbolConflict = (timerProfileItem, profileItem) => {
    const timerProfileSymbols = _.has(timerProfileItem, 'legs.1.symbols') ? (timerProfileItem.legs['1'].symbols || []) : []
    const newTimerProfileItem = _.cloneDeep(timerProfileItem)
    if (_.has(timerProfileItem, 'legs.1.symbols') && _.has(profileItem, 'legs.1.symbols')) {
        newTimerProfileItem.legs['1'].symbols = []
        profileItem.legs['1'].symbols.forEach(symbol => {
            const timerProfileSymbol = _.find(timerProfileSymbols, { name: symbol.name })
            if (timerProfileSymbol) {
                newTimerProfileItem.legs['1'].symbols.push(timerProfileSymbol)
            } else {
                const symbolAttribute = getSymbolAttributeByName(symbol.name)
                newTimerProfileItem.legs['1'].symbols.push({
                    name: symbol.name,
                    params: {
                        QUOTE_SPREAD_THRESHOLD: {
                            mode: 'absolute',
                            value: [null, null]
                        },
                        QUOTE_MAX_SIDE_POS: {
                            mode: 'absolute',
                            value: [null, null]
                        }
                    },
                    switches: symbolAttribute.switchType === SYMBOL_SWITCH_TYPES.BUY_SELL ? {
                        BUY: null,
                        SELL: null
                    } : symbolAttribute.switchType === SYMBOL_SWITCH_TYPES.BUY_SELL_TO_OPEN_CLOSE ? {
                        BUY_OPEN: null,
                        BUY_CLOSE: null,
                        SELL_OPEN: null,
                        SELL_CLOSE: null
                    } : {}
                })
            }
        })
    }
    return newTimerProfileItem
}

export const isProfileSymbolSmartPosAccountNil = ({ profileItem, profileRunningState, symbolName, direction='BUY' }) => {
    const profileAccountName = getProfileAccountNameBySymbol(profileItem, symbolName)[direction]
    return profileItem.started 
        && profileAccountName === 'smart_pos_acct'
        && !_.isNil(profileRunningState) 
        && !_.some(_.has(profileRunningState, 'smartPosAccounts') ? profileRunningState.smartPosAccounts.filter(smartPosAccount => smartPosAccount.account !== 'INVALID_ACCT_ID') : [], { symbol: symbolName, side: direction })
}

export const profileHasNilSmartPosAccount = (profileItem, profileRunningState) => {
    let result = false
    if (_.has(profileItem, 'legs')) {
        const profileSymbolNames = _.reduce(profileItem.legs, (symbolNames, profileLegItem) => {
            if (_.has(profileLegItem, 'symbols')) {
                symbolNames.push(...profileLegItem.symbols.map(symbol => symbol.name))
            }
            return symbolNames
        }, [])
        result = _.some(profileSymbolNames, symbolName => 
            isProfileSymbolSmartPosAccountNil({ profileItem, profileRunningState, symbolName, direction: 'BUY' }) 
            || isProfileSymbolSmartPosAccountNil({ profileItem, profileRunningState, symbolName, direction: 'SELL' }) )
    }
    return result
}

export const getExchangeDisplayName = (exchangeName) => {
    const exchangeDisplayNames = {
        OKEXSPOT: 'OKEX SPOT',
        OKEXSPOTMARGIN: 'OKEX MARGIN',
        OKEXCONTRACT: 'OKEX FUTURE/OPTION',
        OKEXUSDSWAP: 'OKEX USD SWAP',
        OKEXUSDTSWAP: 'OKEX USDT SWAP',
        HUOBI: 'HUOBI SPOT',
        HUOBIFUT: 'HUOBI FUTURES',
        HUOBIFUTSWAP: 'HUOBI SWAP',
        BNBFUTACOIN: 'BNBFUTA COIN',
        BNBFUTAUSDT: 'BNBFUTA USDT',
        BNBFUTABUSD: 'BNBFUTA BUSD',
        BNBFUTAOPT: 'BNBFUTA OPT',
        FTXSPOT: 'FTX SPOT',
        FTXFUT: 'FTX FUTURES',
        FTXOPT: 'FTX OPTION',
        PHEMEXBTCCONTRACT: 'PHEMEX COIN CONTRACT',
        PHEMEXUSDCONTRACT: 'PHEMEX USD CONTRACT'
    }
    return exchangeDisplayNames[exchangeName] || exchangeName
}

export const getProfileSmartPosAccountParamKeys = (exchangeName) => {
    return EXCHANGES_CAN_SUPPORT_SMART_POSITIONS.includes(exchangeName)
        ? [`${exchangeName}_SMART_POS_BID_ACCOUNTS`, `${exchangeName}_SMART_POS_ASK_ACCOUNTS`, `${exchangeName}_SMART_POS_SWITCH_INTERVAL_IN_SEC`, `${exchangeName}_SMART_POS_BACKUP_ACCOUNT`, `${exchangeName}_SMART_ACCTS_ROUND_ROBIN_SWITCH`]
        : []
}

export const profileHasQuantityCappedSymbol = ({ profileItem, profileRunningState={}, symbolItems={}, symbolPricings={} }) => {
    const profileQuoteSymbols = _.has(profileItem, 'legs.1.symbols') ? profileItem.legs['1'].symbols : []
    return _.some(profileQuoteSymbols, profileSymbolItem => {
        const isCapped = isProfileSymbolQuantityCapped({ 
            profileSymbolItem,
            profileRunningState,
            symbolItems,
            symbolPricings
        })
        return isCapped.BUY || isCapped.SELL
    })
}

export const isProfileSymbolQuantityCapped = ({ profileSymbolItem, profilePositionData={}, symbolItems={}, symbolPricings={} }) => {
    const result = {
        BUY: false,
        SELL: false
    }
    const { name: symbolName } = profileSymbolItem
    const symbolPricingItem = symbolPricings[symbolName]
    const symbolLastPrice = symbolPricingItem 
        ? (symbolPricingItem.last ? symbolPricingItem.last 
            : symbolPricingItem.bid && symbolPricingItem.ask ? (symbolPricingItem.bid + symbolPricingItem.ask) / 2 
            : null
        )
        : null

    const symbolPositionData = _.has(profilePositionData, `${symbolName}`) ? profilePositionData[symbolName] : {}
    const symbolVirtualNetPosition = _.reduce(symbolPositionData, (result, positionData) => {
        const { long, open_long, short, open_short } = positionData
        result += (Number(long) + Number(open_long) - Number(short) - Number(open_short))
        return result
    }, 0)

    if (!_.isEmpty(symbolName) 
        && _.has(profileSymbolItem, 'params.QUOTE_TRADE_IN_NOTIONAL_VALUE') && _.isBoolean(profileSymbolItem.params.QUOTE_TRADE_IN_NOTIONAL_VALUE)
        && _.has(profileSymbolItem, 'params.QUOTE_MAX_SIDE_POS') && _.isArray(profileSymbolItem.params.QUOTE_MAX_SIDE_POS) && _.size(profileSymbolItem.params.QUOTE_MAX_SIDE_POS) === 2
        && _.has(profileSymbolItem, 'params.QUOTE_NEW_ORDER_QTY') && _.isArray(profileSymbolItem.params.QUOTE_NEW_ORDER_QTY) && _.size(profileSymbolItem.params.QUOTE_NEW_ORDER_QTY) === 2) {

        const tradeInNotionalValue = profileSymbolItem.params.QUOTE_TRADE_IN_NOTIONAL_VALUE
        const normalizedVirtualNetPosition = tradeInNotionalValue ? getNotional({
            symbolItem: symbolItems[symbolName],
            quantity: symbolVirtualNetPosition,
            price: symbolLastPrice,
            BTCUSDIndexLastPrice: _.has(symbolPricings, `btc_usd_OKEX_INDEX.last`) ? symbolPricings['btc_usd_OKEX_INDEX'].last : null
        }) : symbolVirtualNetPosition
        
        Object.keys(result).forEach(side => {
            const paramIndex = side === 'BUY' ? 0 : 1
            const maxSidePos = profileSymbolItem.params.QUOTE_MAX_SIDE_POS[paramIndex]
            const newOrderQty = profileSymbolItem.params.QUOTE_NEW_ORDER_QTY[paramIndex]

            if (!_.isNil(maxSidePos) && !_.isNil(newOrderQty)) {
                if (side === 'BUY') {
                    result[side] = (normalizedVirtualNetPosition + newOrderQty) >= 0 
                        && (normalizedVirtualNetPosition + newOrderQty) >= maxSidePos
                } else if (side === 'SELL') {
                    result[side] = (normalizedVirtualNetPosition - newOrderQty) <= 0 
                        && (Math.abs(normalizedVirtualNetPosition) + newOrderQty) >= maxSidePos
                }
            }
        })
    }
    return result
}

export const getProfileTradingAccountPortfolios = (profileItem) => {
    const { items: accountItems } = store.getState().account
    const profileSymbolNames = getProfileSymbolNames(profileItem)
    const profileTradingAccountNames = _.reduce(profileSymbolNames, (result, symbolName) => {
        const tradingAccountNames = getProfileTradingAccountNamesBySymbol(profileItem, symbolName)
        result.push(...tradingAccountNames.BUY, ...tradingAccountNames.SELL)
        return result
    }, [])
    const portfolios = Object.keys(_.reduce(profileTradingAccountNames, (result, accountName) => {
        const accountItem = accountItems[accountName]
        if (_.has(accountItem, 'portfolio_name')) {
            result[accountItem.portfolio_name] = accountName 
        }
        return result
    }, {}))
    return portfolios
}